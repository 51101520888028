.footer {
  background: #f5f5f5;
  padding: 30px 0;

  .wrapper {
    max-width: 1200px;

    @include _992 {
      padding: 0 40px;
    }

    @include _768 {
      padding: 0 15px;
    }
  }
}

.footer__inner {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include _768 {
    flex-direction: column;
  }
}

.footer__copyrights-wrapper {
  width: 100%;
  display: flex;
  padding-top: 2px;

  @include _992 {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @include _768 {
    position: static;
    order: 4;
    padding: 0;
    flex-direction: column;
  }
}

.footer__info-column {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include _768 {
    width: 100%;
  }

  &--margin {

    @include _992 {
      margin-right: 0;
      min-width: 192px;
    }

    @include _768 {
      order: 2;
      margin: 0 0 10px;
    }
  }

  &--logo {
    @include _768 {
      order: 0;
      margin-bottom: 15px;
    }
  }
}

.footer__logo-img {
  width: 105px;
  height: 30px;
}

.footer__copyright-text {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: $color--grey2;
  font-family: $font2;

  &--upp {
    text-transform: uppercase;
  }
}

.footer__info-text {
  color: $color--grey3;
  font-size: 13px;
  line-height: 24px;
  font-family: $font2;

  &--big {
    font-weight: 700;
  }

  &--small {
    font-weight: 400;
  }
}

.footer__info-wrapper {
  display: flex;
}

.footer__links {
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  width: calc(100% - 400px);
  max-width: 635px;


  @include _992 {
    flex-wrap: wrap;
    flex-grow: 0;
    width: calc(100% - 400px);
    max-width: none;
  }

  @include _768 {
    margin-bottom: 15px;
    width: auto;
    flex-grow: 1;
    margin-left: 0;
  }
}

.footer__link-item {
  width: 33.33%;
  margin-bottom: 5px;

  @include _992 {
    max-width: none;
    width: 50%;
  }
}

.footer__link {
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: $color--grey3;
  font-family: $font2;

  &:hover {
    text-decoration: underline;
  }
}
