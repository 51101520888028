.basket {
  padding: 45px 0;
}

.basket__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  @include _992 {
    padding-top: 73px;
  }

  @include _768 {
    padding-top: 31px;
  }
}

.basket__breadcrumbs {
  margin-bottom: 3px;

  @include _992 {
    margin-bottom: 6px;
  }

  @include _768 {
    margin-bottom: 8px;
  }
}

.basket__grid {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include _992 {
    align-items: flex-end;
  }
}

.basket__left {
  flex-basis: 42%;

  @include _992 {
    flex-basis: 50%;
    padding-right: 20px;
  }
}

.basket__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #026db0;

  @include _992 {
    margin-bottom: 12px;
  }

  @include _768 {
    font-size: 17.5849px;
    line-height: 17px;
    margin-bottom: 0;
  }
}

.basket__right {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 465px;
  width: 100%;

  @include _992 {
    flex-basis: 50%;
    flex-direction: column;
    align-items: flex-end;
  }
}

.basket__reset-btn {
  max-width: 140px;
  padding: 5px;
  font-weight: 700;
  height: 25px;
  position: relative;
  justify-content: flex-end;
  background: #ffffff;
  color: #858585;
  border: 0.913148px solid #858585;
  margin-bottom: 2px;

  @include _992 {
    margin-bottom: 0;
  }

  span {
    @include _768 {
      width: 20px;
      margin-right: 7px;
      margin-top: 6px;
    }
  }
  .basket__reset-icon {
    position: absolute;
    left: 3px;
    top: 2px;
    color: #858585;
    svg {
      width: 20px;
    }
    @include _768 {
      top: -3px;
    }
  }

  @include _992 {
    order: 0;
    margin-bottom: 41px;
  }

  @include _768 {
    margin-bottom: 0;
  }
}

.basket__submit-btn {
  max-width: 215px;
  min-height: 40px;

  @include _992 {
    order: 1;
    margin-bottom: 6px;
  }

  &--mobile-hide {
    @include _768 {
      display: none;
    }
  }
}

.basket__header {
  margin-bottom: 26px;

  @include _992 {
    margin-bottom: 31px;
  }

  @include _768 {
    margin-bottom: 24px;
  }
}

.basket__table-caption {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #858585;
  font-family: $font2;
  display: flex;
  align-items: center;

  @include _992 {
    font-size: 14px;
    line-height: 16px;
  }
}

.basket__row {
  display: flex;
  align-items: stretch;

  &--supplier {
    display: block;
  }

  &--caption {
    padding-top: 0;
    padding-bottom: 5px;

    @include _768 {
      display: none;
    }
  }

  &--tablet {
    display: none;

    @include _992 {
      display: flex;
      justify-content: space-between;
    }
  }
}

.basket__info-tablet {
  &--01 {
    width: 35%;
  }

  &--02 {
    width: 45%;
    padding-right: 50px;

    a {
      max-width: 220px;
    }
  }

  &--03 {
    width: 20%;
  }
}

.basket__cell {
  &--01 {
    width: 42.5%;

    @include _992 {
      width: 36%;
    }
  }

  &--02 {
    width: 19.5%;

    @include _992 {
      width: 21%;
    }
  }

  &--03 {
    width: 16.5%;

    @include _992 {
      width: 21%;
    }
  }

  &--04 {
    width: 20%;

    @include _992 {
      width: 19%;
    }
  }

  &--05 {
    width: 2%;

    @include _992 {
      width: 3%;
    }
  }

  &--wide {
    width: 100%;
  }

  &--center {
    display: flex;
    align-items: center;
  }

  &--price {
    padding-top: 50px;

    @include _992 {
      padding-top: 75px;
    }
  }

  &--amount {
    padding-top: 49px;

    @include _992 {
      padding-top: 80px;
    }
  }

  &--total {
    padding-top: 43px;

    @include _992 {
      padding-top: 75px;
    }
  }

  &--basket {
    padding-top: 50px;

    @include _992 {
      padding-top: 80px;
    }
  }
}

.basket__table-supplier-caption {
  background: #026db0;
  display: flex;

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-right: 10px;
    display: flex;

    span {
      display: block;

      &:first-letter {
        text-transform: uppercase;
        outline: 2px solid red;
      }
    }
  }

  @include _992 {
    line-height: 24px;
  }

  @include _768 {
    line-height: 21px;
  }
}

.basket__table-supplier-copy-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    fill: #ffffff;
  }
}

.basket__offers-item {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #c4c4c4;
  min-height: 245px;

  @include _992 {
    min-height: 288px;
  }

  @include _768 {
    padding-top: 25px;
    min-height: 276px;
  }

  &:hover {
    background: rgba(2, 109, 176, 0.05);
  }
}

.basket__item-info-wrapper {
  display: flex;
  align-items: stretch;
}

.basket__item-img-wrapper {
  @include _992 {
    height: 190px;
    width: 65px;
    flex-shrink: 0;
  }

  &--mobile {
    width: 60px;
    height: 220px;
    margin-right: 14px;
    flex-shrink: 0;
    padding-bottom: 39px;
  }
}

.basket__item-body {
  &--mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.basket__item-img {
  object-fit: contain;
  width: 100px;
  height: 185px;
  flex-shrink: 0;
  background: transparent;
  margin-left: -25px;
  margin-right: 23px;
  padding-bottom: 14px;

  @include _992 {
    object-fit: contain;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
  }
}

.basket__profuct-info {
  display: flex;
  flex-direction: column;

  @include _992 {
    flex-grow: 1;
    align-items: stretch;
  }
}

.basket__item-title {
  font-family: $font2;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 6px;

  @include _992 {
    line-height: 15px;
    height: 46px;
    overflow: hidden;
    margin-bottom: 9px;
  }

  @include _768 {
    width: 100%;
    height: 62px;
  }
}

.basket__item-volume {
  font-family: $font2;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  color: #000000;

  @include _768 {
    margin-bottom: 15px;
  }
}

.basket__item-row {
  &--desktop {
    @include _992 {
      display: none;
    }
  }

  &--mtauto {
    margin-top: auto;
  }

  &--tablet-info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &--mobile-volume {
    margin-bottom: 8px;
  }

  &--mobile-info {
    @include _768 {
      margin-bottom: 22px;
      display: flex;
      align-items: flex-end;

      .basket__item-link {
        padding-left: 10px;
      }
    }
  }

  &--price {
    @include _768 {
      display: flex;
      margin-top: auto;
    }
  }
}

.basket__item-row-column {
  width: 50%;
}

.basket__item-vendor-code {
  font-family: $font2;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #858585;
  margin-bottom: 10px;
  display: block;

  span {
    font-weight: bolder;
  }
}

.basket__item-brand-name {
  display: block;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #858585;

  a {
    color: $color--main;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-decoration: underline;
  }
}

.basket__item-link {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-decoration: underline;
  display: block;
  color: #026db0;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  @include _992 {
    font-size: 12px;
    line-height: 16px;
  }
}

.basket__item-price {
  font-family: $font2;
  @include _768 {
    margin-left: 21px;
  }
  span {
    &:first-of-type {
      font-weight: 500;
      font-size: 21.037px;
      line-height: 25px;
      color: #000000;

      @include _992 {
        font-size: 26px;
        line-height: 30px;
      }
    }

    &:last-of-type {
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      color: #767676;

      @include _992 {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.basket__item-total-price {
  font-family: $font2;
  span {
    &:first-of-type {
      font-weight: 500;
      font-size: 29.7087px;
      line-height: 35px;
      color: #000000;

      @include _992 {
        font-size: 26px;
        line-height: 30px;
      }
    }

    &:last-of-type {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: #575757;

      .measurements {
        color: #000000;
      }

      @include _992 {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.basket__item-change-amount-block {
  display: flex;
  align-items: center;
  width: 100px;
}

.basket__item-btn {
  width: 25px;
  height: 25px;
  padding: 0;

  span {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 2px;
      background: #ffffff;
    }
  }

  &--increase {
    span {
      &::after {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 2px;
        height: 15px;
        background: #ffffff;
      }
    }
  }
}

.basket__item-amount {
  width: 50px;
  height: 100%;
  text-align: center;
}

.basket__radiobutton {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  border: 2px solid #b0b0b0;
  cursor: pointer;

  &--checked {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #b0b0b0;
    }
  }
}

.basket__total-inner {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 60px;

  @include _992 {
    padding-top: 25px;
  }

  @include _768 {
    padding-top: 20px;
  }
}

.basket__total-description {
  width: 45%;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-family: $font2;

  @include _992 {
    width: 50%;
    max-width: 290px;
  }

  @include _768 {
    display: none;
  }
}

.basket__total-order-info {
  width: 47.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include _992 {
    width: 50%;
    max-width: 330px;
    padding-top: 3px;
  }

  @include _768 {
    width: 100%;
    max-width: none;
  }

  &--tablet {
    display: none;

    @include _992 {
      display: block;
      width: 100%;
    }

    @include _768 {
      display: none;
    }
  }
}

.basket__info-list {
  width: 100%;

  &--margin-bottom {
    margin-bottom: 60px;

    @include _992 {
      margin-bottom: 70px;
    }

    @include _768 {
      margin-bottom: 25px;
    }
  }
}

.basket__info-list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 10px;

    @include _992 {
      margin-bottom: 17px;
    }

    @include _768 {
      margin-bottom: -3px;
    }
  }
}

.basket__order-info-title {
  font-weight: normal;
  font-family: $font2;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  position: relative;
  z-index: 5;
  background: #ffffff;

  @include _992 {
    font-size: 18px;
    line-height: 22px;
    max-width: none;
  }
}

.basket__order-info-dots {
  display: block;
  flex-grow: 1;
  height: 5px;
  background-image: radial-gradient(circle, currentcolor 1px, transparent 1.5px);
  background-position: bottom;
  background-size: 9px 4.5px;
  background-repeat: space no-repeat;
  transform: translateY(-3px);
  margin: 0 5px;
}

.basket__order-info-value {
  position: relative;
  z-index: 5;
  background: #ffffff;

  &--amount {
    font-weight: 500;
    font-size: 22.5437px;
    line-height: 22px;
    color: #000000;
    font-family: $font2;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: #575757;
    }
  }
}

.basket__total-price {
  font-family: $font2;

  span {
    &:first-of-type {
      font-weight: 600;
      font-size: 29.7087px;
      line-height: 35px;
      color: #026db0;

      @include _992 {
        font-size: 30px;
        line-height: 30px;
      }
    }

    &:last-of-type {
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: #575757;

      .measurements {
        color: #000000;
      }

      @include _992 {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.basket__order-btn {
  font-weight: 900;
  font-size: 21.7778px;
  line-height: 26px;
  text-align: center;
  width: 100%;
  max-width: 280px;
  padding: 13px 0;

  @include _768 {
    margin: 0 auto;
  }
}
