.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}


.layout__inner {
  flex-grow: 1;
}
