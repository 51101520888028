.checkout {
  padding-top: 45px;
  padding-bottom: 65px;

  .wrapper {
    @include _992 {
      padding: 0 40px;
    }

    @include _768 {
      padding: 0 10px;
    }
  }
}

.checkout__inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include _992 {
    padding-top: 73px;
    flex-direction: column;
  }

  @include _768 {
    padding: 40px 5px 0;
  }
}

.checkout__header-wp {
  width: 100%;
  margin-left: -5px;

  @include _992 {
    order: 0;
    margin-bottom: 7px;
    margin-left: 0;
  }

  @include _768 {
    margin-bottom: 4px;
  }
}

.checkout__form-wp {
  padding-top: 5px;
  flex-basis: 66%;
  flex-shrink: 1;
  margin-left: -5px;

  @include _992 {
    order: 2;
    margin-left: 0;
  }

  @include _768 {
    padding-top: 0;
  }
}

.checkout__order-info-wp {
  padding-top: 17px;
  margin-right: 5px;

  @include _992 {
    order: 1;
    margin-bottom: 37px;
    padding-top: 0;
    margin-right: 0;
  }

  @include _768 {
    margin-bottom: 23px;
  }
}

.checkout__title {
  font-weight: bold;
  color: #026db0;
  font-family: $font2;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 10px;

  @include _992 {
    margin-bottom: 7px;
    font-size: 24px;
    line-height: 35px;
  }

  @include _768 {
    font-size: 14px;
    margin-bottom: 13px;
  }

  &--bottom {
    margin-bottom: 11px;

    @include _992 {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 35px;
    }

    @include _768 {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
}

.checkout__sub-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  color: #858585;
  margin-bottom: 30px;

  @include _992 {
    margin-bottom: 15px;
  }

  @include _768 {
    display: none;
  }
}

.checkout__field-wp {
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
  font-family: $font2;

  @include _768 {
    margin-bottom: 3px;
  }
}

.checkout__field {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @include _992 {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  @include _768 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &--last {
    margin-bottom: 46px;

    @include _992 {
      margin-bottom: 40px;
    }

    @include _768 {
      margin-bottom: 20px;
    }
  }
}

.checkout__tablet-wp {
  @include _992 {
    display: flex;
    background: #026db0;
    padding: 0 5px;
  }

  @include _768 {
    background: #ffffff;
  }

  @include _768 {
    flex-direction: column;
    background: #f5f9fc;
  }
}

.checkout__label {
  font-family: $font2;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: right;
  color: #000000;
  flex-grow: 1;
  flex-shrink: 2;
  margin-right: 20px;

  @media (max-width: 1100px) {
    font-size: 18px;
    line-height: 22px;
    margin-right: 13px;
    white-space: nowrap;
  }

  @include _992 {
    font-size: 18px;
    line-height: 22px;
    margin-right: 13px;
    white-space: nowrap;
  }

  @include _768 {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.checkout__input {
  width: 66%;
  border: 1px solid #b0b0b0;
  flex-shrink: 0;
  padding: 0 15px;
  height: 40px;

  @media (max-width: 1180px) {
    width: 60%;
  }

  @include _992 {
    width: 74.5%;
    height: 40px;
  }

  @include _768 {
    width: 100%;
  }

  &--text {
    width: 100%;
    height: auto;
    padding: 10px;

    @include _768 {
      height: 105px;
    }
  }

  &--error {
    border-color: red;
  }
}

.checkout__from-center {
  width: 66%;
  margin-left: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1180px) {
    width: 60%;
  }

  @include _992 {
    width: 75%;
  }

  @include _768 {
    width: 100%;
    padding-top: 8px;
  }
}

.checkout__btn {
  font-weight: 900;
  font-size: 21.7778px;
  line-height: 26px;
  text-align: center;
  padding: 8px 0;
  width: 100%;
  max-width: 275px;
  margin-bottom: 45px;
  align-self: center;
  min-height: 56px;
  margin-right: 31px;

  @include _992 {
    margin-left: auto;
    margin-bottom: 35px;
    margin-right: 0;
  }

  @include _768 {
    margin: 0 auto 20px;
  }
}

.checkout__info-text {
  font-family: $font2;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  width: 65%;
  margin-left: auto;
  margin-right: 23px;

  @include _992 {
    width: 100%;
    margin: 0;
  }
}

.checkout__order-info {
  width: 275px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;

  @include _992 {
    width: 100%;
    margin-bottom: 8px;
  }

  @include _768 {
    margin: 0 -15px;
    width: auto;
    padding: 0 10px;
    background: #f5f9fc;
    box-shadow: none;
  }
}

.checkout__order-inner {
  padding: 30px 12px 0 12px;

  @include _992 {
    padding: 5px 10px 0;
  }
}

.checkout__order-amount {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-right: 10px;

  @include _992 {
    border: none;
    width: 50%;
    margin-bottom: 0;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
  }

  @include _768 {
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.checkout__order-info-dots {
  display: block;
  flex-grow: 1;
  height: 5px;
  background-image: radial-gradient(circle, currentcolor 1px, transparent 1.5px);
  background-position: bottom;
  background-size: 9px 4.5px;
  background-repeat: space no-repeat;
  transform: translateY(-3px);
  margin: 0 5px;
}

.checkout__order-amount-title {
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  font-family: $font2;
  max-width: 50%;

  @include _992 {
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    color: $color--yellow;
    margin-right: 15px;
    width: 100%;
    max-width: 185px;
  }

  @include _768 {
    color: #000000;
    margin-right: 0;
  }
}

.checkout__order-amount-value {
  font-weight: 500;
  font-size: 22.5437px;
  line-height: 26px;
  color: #026db0;

  @include _992 {
    color: #ffffff;
    font-size: 29px;
    line-height: 34px;
  }

  @include _768 {
    color: #000000;
    font-size: 24px;
    line-height: 28px;
  }

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #575757;

    @include _992 {
      color: #ffffff;
      font-size: 19px;
      line-height: 20px;
    }

    @include _768 {
      color: #575757;
    }
  }
}

.checkout__info-items {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding-right: 10px;

  @include _992 {
    flex-wrap: wrap;
    margin-bottom: 0;
    flex-direction: row;
    padding-right: 0;
  }
}

.checkout__info-item {
  @include _992 {
    width: 50%;
    display: flex;
    justify-content: flex-start;

    &:nth-child(2n) {
      justify-content: flex-end;
    }
  }
}

.checkout__info-item-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;

  @include _992 {
    max-width: 265px;
    width: 100%;
    justify-content: flex-start;
  }
}

.checkout__info-item-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-family: $font2;
  max-width: 100px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  @include _992 {
    font-size: 14px;
    line-height: 18px;
    max-width: 140px;
    width: 100%;
  }
}

.checkout__info-item-price {
  span {
    &:first-of-type {
      font-weight: 500;
      font-size: 16.2857px;
      line-height: 19px;
      color: #000000;
    }

    &:last-of-type {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #575757;
    }
    .measurements {
      color: #575757;
    }
  }
}

.checkout__info-item-amount {
  font-weight: normal;
  font-size: 10.8571px;
  line-height: 13px;
  color: #858585;

  @include _992 {
    max-width: 45px;
    display: block;
    width: 100%;
  }
}

.checkout__order-price {
  background: #026db0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px  20px 4px 12px;

  @include _992 {
    width: 50%;
    justify-content: flex-end;
  }

  @include _768 {
    background: #f5f9fc;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    align-items: flex-end;
  }
}

.checkout__order-price-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  font-family: $font2;
  color: $color--yellow;

  @include _992 {
    margin-right: 15px;
    font-size: 18px;
    line-height: 20px;
  }

  @include _768 {
    color: #000000;
    margin-right: 0;
  }
}

.checkout__order-price-value {
  font-family: $font2;

  span {
    &:first-of-type {
      font-weight: 500;
      font-size: 29px;
      line-height: 35px;
      color: #ffffff;

      @include _768 {
        color: $color--main;
        font-size: 24px;
        line-height: 28px;
      }
    }

    &:last-of-type {
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: #ffffff;

      @include _768 {
        color: #575757;
        .measurements {
          color: #000000;
        }
      }
    }
  }
}

.checkout__order-suppliers-info {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  font-family: $font2;
  color: #707070;

  @include _992 {
    margin-left: 8px;
  }

  @include _768 {
    margin: 0 -15px;
    width: auto;
    padding: 8px 15px 0;
    background: #f5f9fc;
  }
}

.checkout__order-supplier {
  color: #026db0;
  text-decoration: underline;

  &:not(:last-of-type) {
    margin-right: 5px;

    &::after {
      content: ",";
      display: inline-block;
    }
  }
}

.checkout__popup {
  background: #026db0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  max-width: 550px;
  margin: 0 auto;
  padding: 40px 70px 35px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  @include _768 {
    max-width: 90%;
    width: 100%;
  }
}

.checkout__popup-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  font-family: $font2;
  margin-bottom: 35px;
}

.checkout__popup-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  font-family: $font2;
  text-align: center;
  color: #ffffff;
}

.checkout__popup-close-btn {
  position: absolute;
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ffffff;
  right: 8px;
  top: 8px;
  transform: translate(50%, -50%);
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    background: $color--main;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.error-wrap {
  margin-left: 35%;
  padding-top: 5px;
  color: red;

  @media (max-width: 1180px) {
    margin-left: 40%;
  }

  @include _992 {
    margin-left: 26%;
    padding-top: 5px;
  }
  @include _768 {
    margin-left: 1%;
  }
}
