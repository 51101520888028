.brand__catalog-controls {

  @include _992 {
    margin-top: 20px;
  }

  @include _768 {
    margin-top: 40px;
  }
}
