.recommendations__title {
  font-family: $font1;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  color: $color--main;
}

.recommendations__catalog {
  margin-bottom: 25px;
}

.recommendations__link {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;
  color: $color--main;
  display: flex;
  justify-content: center;
}
