.search__header {
  margin-bottom: 15px;
}

.search__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #026db0;
  font-family: $font2;

  @include _992 {
    margin-bottom: 25px;
  }

  @include _768 {
    font-size: 17px;
    line-height: 18px;
  }
}

.search__result-count {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #313140;
  font-family: $font2;
}

.search__main--side {

  @include _768 {
    display: none;
  }
}

.search__main--side .search__filters--desktop {
  @include _768 {
    display: none;
  }
}
