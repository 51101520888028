.counts__list {
  display: flex;
  flex-direction: column;

  @include _992 {
    flex-direction: row;
    gap: 15px;
  }

  @include _768 {
    flex-direction: column;
    gap: 0;
  }
}

.counts__item {
  background: $color--main;
  border: 2px solid $color--main;

  &:not(:last-of-type) {
    margin-bottom: 18px;

    @include _992 {
      margin-bottom: 0;
    }

    @include _768 {
      margin-bottom: 8px;
    }
  }

  &--link:hover,
  &--active {
    border: 2px solid $color--yellow;
    background: $color--main-dark;
  }

  @include _992 {
    width: 33.33%;
    margin-bottom: 0;
  }

  @include _768 {
    width: auto;
    margin-bottom: 8px;
  }
}

.counts__link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 106px;
  padding: 0 10px;
  box-sizing: content-box;
  font-family: $font2;

  @include _768 {
    min-height: 68px;
    padding: 2px 10px;
    box-sizing: border-box;
  }
}

.counts__title {
  font-weight: bold;
  font-size: 24.566px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.counts__amount {
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: #ffc01d;
  margin-bottom: 10px;

  @include _768 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
  }
}

.counts__text {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  text-transform: lowercase;
  color: #ffffff;

  @include _768 {
    font-size: 18px;
    line-height: 18px;
  }
}
