.items {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;

  &--fixed-height {
    height: 100%;
  }

  @include _992 {
    height: auto;
    width: 100%;
    max-width: none;
    justify-content: flex-start;
    margin-bottom: 48px;

    & + & {
      margin-bottom: 47px;

      @include _768 {
        margin-bottom: 33px;
      }
    }
  }

  @include _768 {
    margin-bottom: 29px;
  }
}

.items__title {
  font-family: $font2;
  background: #026db0;
  font-weight: 800;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;

  @include _992 {
    font-size: 24px;
    line-height: 35px;
    text-align: left;
    padding: 4px 0 4px 21px;
    text-transform: uppercase;
  }

  @include _768 {
    padding: 12px 0 12px 20px;
    font-size: 17px;
    line-height: 17px;
  }
}

.items__list-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 25px;

  .sidebar__brands--fixed-height & {
    max-height: 536px;

    @include _992 {
      max-height: 536px;
      padding-top: 0;
    }
  }

  @include _992 {
    max-height: 536px;
    padding-top: 0;
  }
}

.items__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  height: 100%;

  @include _768 {
    padding-bottom: 0 !important;
  }
}

.items {
  .swiper-scrollbar {
    background: inherit;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-scrollbar-drag {
    height: 67px;
    width: 5px !important;
    border-radius: 0;
    background-color: #c4c4c4;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 75px;

    @include _768 {
      min-height: 107px;
    }
  }

  .swiper-wrapper {
    display: flex;
    flex-direction: column;

    @include _992 {
      flex-direction: row;
      padding: 0;
      align-items: center;
    }
  }

  .swiper-wrapper {
    @include _992 {
      padding-top: 0;
    }
  }
}

.items__item {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;

  &--brand {
    margin-bottom: 25px;
    max-height: 85px;
  }

  &--supplier {
    padding: 0 10px;
    box-sizing: border-box;
    max-height: 75px;
  }

  @include _992 {
    flex-shrink: 1;
    margin-bottom: 0;
    max-height: none;
    padding: 0;
  }

  img {
    width: 195px;
    height: 85px;
    object-fit: contain;

    @include _768 {
      width: 195px;
      height: 65px;
    }
  }

  img:first-of-type {
    @include _992 {
      margin-top: 0;
    }
  }
}

.items__link {
  width: 195px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--supplier {
    border-bottom: 0.2px solid #c4c4c4;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    @include _992 {
      border-bottom: none;
      height: 91px;
    }

    @include _768 {
      height: 76px;
    }
  }

  &--brand {
    height: 115px;

    @include _768 {
      height: 88px;
    }
  }
}

.item__supplier-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  font-family: $font2;

  @include _992 {
    display: none;
  }
}

.item__img-wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  &--supplier img {
    height: 40px!important;
  }

  @include _992 {
    margin-bottom: 0;
    height: 85px;

    &--supplier {
      width: 61px;
    }
  }

  @include _768 {
    height: 65px;
  }
}

.item__img {
  object-fit: contain;
  width: 100% !important;
  height: 100% !important;
  object-position: 50% 50%;
}
