.catalog {
  margin-bottom: -25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 845px;

  @include _992 {
    margin: 0 auto;
    max-width: none;
  }

  @include _768 {
    padding-top: 10px;
  }

  @media (max-width: 520px) {
    width: 100%;
  }
}

.catalog__title {
  font-family: $font2;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
  color: #026db0;
  margin-bottom: 23px;

  @include _992 {
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;
  }

  &--empty {
    text-align: center;

    @include _992 {
      margin-top: 15px;
    }
  }

  @include _992 {
    margin-bottom: 24px;
  }

  @media (max-width: 520px) {
    font-size: 18px;
    line-height: 16px;
    margin-top: 15px;
    margin-bottom: 11px;
  }

  @include _375 {
    padding-top: 2px;
    margin-bottom: 2px;
  }
}

.catalog__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px;

  @include _1280 {
    margin-bottom: 25px;
  }

  @include _992 {
    justify-content: space-between;
  }

  @media (max-width: 520px) {
    margin: 0 0 25px 0;
  }
}

.catalog__item {
  position: relative;
  width: 25%;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  margin-bottom: 25px;
  padding: 0 7.5px;
  min-height: 315px;

  @include _1280 {
    margin-bottom: 25px;
    width: 33.33%;
  }

  @media (max-width: 1000px) {
    width: 50%;

    &--wide {
      width: 33.33%;
    }
  }

  @include _992 {
    min-height: auto;
  }

  @include _768 {
    width: 50%;
  }

  @media (max-width: 520px) {
    width: 100%;
    flex-direction: row;
    margin-bottom: 0;
    padding: 0;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  &:hover .catalog__item-hover-text {
    display: block;

    @include _992 {
      display: none;
    }
  }

  &:hover .catalog__item-price-info {
    color: $color--main;
  }

  &:hover .measurements {
    color: $color--main;
  }

  &:hover .quantity {
    color: #767676;
  }
}
.catalog__item-price-info{
  display: flex;

}
.catalog__item-link {
  display: flex;
  align-items: stretch;
  width: 100%;

  @media (min-width: 991px) {
    .catalog__item:hover & {
      position: absolute;
      top: 0;
      left: 7.5px;
      right: 7.5px;
      z-index: 5555;
      width: auto;
    }
  }
}

.catalog__item-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 17px 0;
  min-height: 315px;
  cursor: pointer;
  background: #ffffff;
  width: 200px;
  border: 1px solid #ebebeb;
  margin: 0 auto;

  &:hover {
    box-shadow: 0 3.9859px 12px rgba(0, 0, 0, 0.15);
  }

  @include _1280 {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    padding: 12px 14px 2px;
  }

  @include _992 {
    min-height: 315px;
  }

  @include _768 {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    max-width: none;
    min-height: 315px;
    padding: 12px 10px;
    width: 100%;
  }

  @media (max-width: 520px) {
    flex-direction: row;
    max-width: 100%;
    min-height: 168px;
    padding: 0;
  }
}

.catalog__item-hover-text {
  display: none;
  position: absolute;
  margin-top: 106px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 25px;
  background: rgba(102, 102, 102, 0.7);
  border-radius: 3.9859px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 6666;

  &:hover {
    background: rgba(102, 102, 102, 1);
  }
}

.catalog__item-img-wrapper {
  @include _1280 {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
  }

  @media (max-width: 520px) {
    width: 140px;
    max-width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 0.25px solid rgba(0, 0, 0, 0.15);
  }
}

.catalog__item-region-flag {
  position: absolute;
  right: 17px;
  top: 13px;
  z-index: 5;
  width: 36px;
  height: 24px;
  display: block;
  border: 0.25px solid #858585;

  &--ab {
    background: url("/images/Flag_of_the_Republic_of_Abkhazia.svg.png");
  }

  &--os {
    background: url("/images/50px-Flag_of_South_Ossetia.svg.png");
  }

  img {
    width: 100%;
    height: 100%;
  }

  @include _1280 {
    width: 40px;
    height: 27px;
    right: 14px;
    top: 21px;
  }
  @include _768 {
    right: 15px;
    top: 15px;
    width: 34px;
    height: 24px;
  }

  @include _375 {
    top: 19px;
  }
}

.flag-icon-background,
.flag-icon {
  background-size: cover;
}

.catalog__item-image {
  position: relative;
  width: 100%;
  height: 135px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  img {
    margin: 0 auto;
    max-height: 100%;

    @include _1280 {
      object-fit: contain;
      width: 100%;
      height: 100%;
      max-width: none;
    }
  }

  @include _1280 {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    max-height: 180px;
    width: 100%;
  }

  @media (max-width: 520px) {
    margin: 0;
    height: 130px;
    padding: 0;

    img {
      margin-left: auto;
    }
  }
}

.catalog__item-title {
  font-family: $font2;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #232323;

  @media (min-width: 991px) {
    .catalog .catalog__item:not(:hover) &,
    .position__catalog-wrap .catalog__item & {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-bottom: 0;
    }
  }

  @include _1280 {
    font-size: 16px;
    line-height: 18px;
  }

  @include _992 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-bottom: 0;
  }

  @media (max-width: 520px) {
    font-size: 16px;
    line-height: 19px;
  }
}

.catalog__item-info {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  height: 100%;
  justify-content: space-between;

  @include _1280 {
    box-sizing: border-box;
    justify-content: space-between;
    flex-grow: 1;
  }

  @include _520 {
    padding: 10px 15px;
  }
}

.catalog__item-wp {
  margin-top: auto;

  @include _992 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 18px;
    justify-content: space-between;
  }

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}

.catalog__item-measure {
  font-weight: 400;
  font-size: 12px;
  line-height: 12.4px;
  padding-top: 10px;
  color: #575757;

  @include _992 {
    margin-bottom: 0;
    padding-bottom: 10px;
  }

  @media (max-width: 520px) {
    align-self: flex-start;
    margin-bottom: 0;
    padding: 10px 0;
  }
}

.catalog__item-provider {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: $color--main;
  margin-bottom: 3px;
  padding-top: 10px;
  font-family: $font2;

  @include _992 {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
    padding-top: 0;
  }

  @include _768 {
    margin-left: 0;
  }

  @media (max-width: 520px) {
    font-size: 12px;
    line-height: 14px;
  }
}

.catalog__item-price-info {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 8px;

  @include _1280 {
    margin-bottom: 0;
  }

  @include _992 {
    padding-bottom: 15px;
  }

  @include _520 {
    text-align: left;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.catalog__item-price {
  font-size: 24px;
  line-height: 28px;
  font-family: $font2;
}

.catalog__item-price {
  display: flex;

  span {
    &:first-of-type {
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;

      @include _375 {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &:last-of-type {
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      color: #767676;

      @include _375 {
        font-size: 12px;
      }
    }

    .measurements {
      color: #767676;
    }

    .quantity {
      color: #767676;
    }
  }

  @include _375 {
    font-size: 17px;
  }
}

.catalog__info-wrapper {
  &--center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 75px;
  }
}

.catalog__warning-title {
  text-align: center;
  font-family: $font2;
  color: $color--main;
  font-weight: 700;
  font-size: 13px;
  line-height: 7px;
}

@import "./CatalogSlider.scss";

.image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.image__placeholder {
  width: 135px;
  height: 135px;

  &--hide {
    display: none;
  }
}

.image__main-img {
  visibility: hidden;
  width: 0;
  height: 0;

  &--show {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    visibility: visible;
  }
}
