.header {
  @include _992 {
    width: 100%;
    z-index: 600;
  }

  @include _992 {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10000;
  }
}

// ------------------- header__info ------------------- //

.header__city-selector,
.header__how-to-buy,
.header__phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 20px;

  @include _992 {
    display: none;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }

  &-text {
    font-family: $font2;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}

.header__city-selector {
  @include _1140 {
    margin-right: 0;
  }
}

.header__city-selector-text {
  margin-top: 3px;
}

.header__how-to-buy {
  margin-right: 0;

  &-text {
    min-width: 76px;
  }
}

.header__phone {
  margin-right: 8px;

  &-text {
    min-width: 113px;
  }
}

.header__contacts {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .header__city-selector {
    margin: 0;
  }

  .header__info-inner & {
    height: 22px;

    .header__city-selector,
    .header__how-to-buy,
    .header__phone,
    .header__contacts-divider-wrapper {
      display: flex;
    }

    .header__contacts-divider-wrapper {
      margin: 0 12px;
    }

    .header__how-to-buy-text,
    .header__phone-text {
      margin-top: 3px;
    }
  }
}

.header__contacts-divider {
  height: 20px;
  width: 0;
  border-left: 1px solid #e5eaed;

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    margin-right: 14px;

    @include _1140 {
      margin: 0 12px;
    }

    @include _992 {
      display: none;
    }
  }
}

.header__info {
  background: #f5f5f5;
  display: none;

  @include _992 {
    display: block;
    background: #ffffff;

    .wrapper {
      padding: 0 40px;
    }
  }
}

.header__info-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 6px;
  padding-bottom: 11px;

  @include _768 {
    display: none;
  }
}

.header__warning {
  position: relative;

  &:hover {
    .header__tooltip {
      display: block;
    }
  }
}

.header__tooltip {
  position: absolute;
  top: 0;
  right: -10px;
  width: 210px;
  transform: translateX(100%);
  background: #ffffff;
  border: 1px solid #026db0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  padding: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #026db0;
  display: none;
  z-index: 10100;
}

.header__main {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9998;
  background-color: #ffffff;
  @include _992 {
    z-index: 20;
    position: static;
  }
  .wrapper {
    @include _992 {
      background: $color--main;
      max-width: none;
      padding: 0;
      z-index: 20;
      position: relative;
    }
  }
}

.header__main-wp {
  padding: 15px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include _992 {
    align-items: center;
    position: relative;
    padding: 15px 40px;
    margin: 0 -12px;
  }

  @include _768 {
    padding: 13px 8px;
    margin: 0;
    position: relative;
  }
}

// ------------------- header__logo ------------------- //

.header__logo {
  display: flex;
  align-items: center;
  margin-right: 20px;

  &--desktop {
    height: 48px;
    margin-right: 0;

    @include _992 {
      display: none;
    }
  }

  &--tablet {
    display: none;

    @include _992 {
      display: flex;
    }

    @include _768 {
      max-width: 102px;
      margin-right: auto;

      svg {
        width: 100px;
        height: 16px;
      }
    }
  }

  &--menu-tablet {
    display: none;

    @include _992 {
      display: block;

      svg {
        width: 120px;
      }
    }

    @include _768 {
      display: none;
    }
  }

  @include _992 {
    max-width: 120px;
    width: 100%;
    margin: 0 0 0 12px;
  }

  @include _768 {
    max-width: 120px;
    height: 31px;
    margin: 0 auto;
  }

  &--menu {
    margin: 0;

    @include _768 {
      svg {
        width: 120px;
        height: 30px;
      }
    }
  }

  &--menu-mobile {
    display: none;

    @include _768 {
      display: block;
      width: 98px;
      height: 27px;

      svg {
        width: 98px;
        height: 27px;
      }
    }
  }
}

.header__logo-link {
  display: block;
}

.header__logo-img {
  &--01 {
    margin-left: 5px;
    margin-right: 3px;
    height: 22px;
    width: 60px;

    @include _992 {
      width: 85px;
      height: 30px;
    }

    svg {
      width: 100%;
      height: 100%;
      fill: #000000;

      @include _992 {
        fill: #ffffff;
      }
    }
  }

  &--02 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    margin-right: 18px;
    width: 26px;

    @include _992 {
      width: 28px;
      height: 28px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @include _992 {
    width: 122px;
    height: 32px;
  }

  @include _768 {
    width: 100px;
    height: 26px;
  }
}

.header__logo2-wrapper {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: #006cb6;

  @include _992 {
    background: #ffffff;
  }
}

.header__logo-text {
  margin-left: -2px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  max-width: 210px;
  font-family: $font2;

  span {
    display: block;
  }

  @include _992 {
    display: none;
  }
}

// ------------------- header__search ------------------- //

.header__search-form {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: space-between;
  max-width: 245px;

  @include _1140 {
    margin: 0 8px;
  }

  @include _992 {
    margin-left: 12px;
    margin-right: 0;
    max-width: unset;
  }

  @include _768 {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    transform: translateY(100%);
    min-height: 41px;

    &:not(.show) {
      display: none;
    }
  }
}

.header__select {
  max-width: 115px;
  background: #ffffff;
  border: 1px solid #026db0;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  min-height: 28px;
  box-sizing: border-box;
  font-family: $font2;
  flex-shrink: 0;

  @include _992 {
    display: none;
  }
}

.header__search-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include _992 {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background: #ffffff;
    border: 2px solid #026db0;
    box-sizing: border-box;
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    left: 30px;
    right: 30px;
    overflow: hidden;

    @include _768 {
      bottom: -5px;
      left: 15px;
      right: 15px;
      border-radius: 10px;
    }
  }
}

.header__search-input-wrapper {
  flex-grow: 1;
  position: relative;
  display: flex;
}

.header__search-input {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 32px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #e5eaed;
  border-radius: 4px;
  font-family: $font2;
  outline: none;

  /* clears the ‘X’ from Internet Explorer */
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  @include _1140 {
    padding-left: 8px;
  }

  @include _992 {
    padding: 0 7px 0 12px;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    border: 1px #026db0;
    border-radius: 6px;
  }

  @include _768 {
    font-size: 16px;
    line-height: 14px;
    padding: 2px 15px;
    min-height: 22px;
    border: none;
    border-radius: 0;
  }

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-ms-input-placeholder,
  &::-moz-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #969ba3;
    font-family: $font2;

    @include _992 {
      font-size: 16px;
      line-height: 19px;
      font-family: $font2;
    }

    @include _768 {
      font-size: 14px;
      line-height: 16px;
      font-family: $font2;
    }
  }
}

// ---------------------------------instant--search--------------------------------- //

.header__results {
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 999;

  .product-item img {
    height: 18px;
  }

  &--empty {
    color: $color--main;
    padding: 3px 10px;
    font-family: $font2;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  @include _768 {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
    padding: 12px 27px;

    &--empty {
      bottom: auto;
      padding: 12px 40px;
      text-align: center;
    }
  }
}

.header__product-list {
  padding: 6px 0;

  & + & {
    border-top: 1px solid rgba(2, 109, 176, 0.3);
  }
}

.header__product-item,
.header__search-item {
  a {
    display: flex;
    padding-right: 10px;
    align-items: center;
    max-width: 100%;

    @include _768 {
      padding-right: 0;
    }

    &:hover {
      background: rgba(2, 109, 176, 0.05);
    }
  }
}

.product-item__img {
  width: 25px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @include _992 {
    width: 40px;
    height: 33px;
  }

  @include _768 {
    width: 10%;
    max-width: 40px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.product-item__name-wrapper {
  font-family: $font2;
  font-size: 10px;
  line-height: 24px;
  font-weight: 500;
  flex-grow: 1;
  width: calc(100% - 150px - 50px);

  @include _992 {
    font-size: 15px;
    line-height: 52px;
  }

  @include _768 {
    line-height: 40px;
    width: 50%;
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;

    @include _992 {
      max-width: 390px;
    }

    @include _768 {
      width: 100%;
    }
  }
}

.product-item__price-wrapper {
  max-width: 150px;
  width: 100%;
  text-align: right;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  @include _768 {
    width: 40%;
  }
}

.product-item__price-prefix {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  display: flex;
  margin-right: 3px;

  @include _992 {
    font-size: 13px;
  }
}

.product-item__price {
  .price__full {
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;

    @include _992 {
      font-size: 18px;
    }
  }

  .price__decimal {
    font-size: 10px;
    line-height: 12px;

    @include _992 {
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

.header__search-button {
  &--desktop {
    box-sizing: border-box;
    position: absolute;
    top: 5px;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    padding: 0;
    border: none;
    border-radius: 4px;
    background: #006db7;

    @include _992 {
      display: none;
    }
  }

  &-icon {
    height: 16px;
    width: 16px;
  }

  &--tablet {
    display: none;

    @include _992 {
      display: block;
      position: absolute;
      right: 9px;
      width: 22px;
      height: 22px;
      top: 50%;
      transform: translateY(-50%);

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.header__search-delete-icon-tablet {
  top: 50%;
}

.header__float-btn {
  display: none;
  min-height: auto;

  @include _992 {
    display: none;
  }
}

.header__brand-item {
  padding: 0 5px;
}

.header__brand-text {
  font-size: 10px;
  line-height: 24px;
  color: #000000;

  &--title {
    font-weight: 700;
  }

  &--name {
    font-weight: 400;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.header__types-item {
  font-size: 10px;
  line-height: 24px;
  color: #000000;
  font-weight: 400;

  &:first-letter {
    text-transform: uppercase;
  }
}

// ------------------- header__user-menu ------------------- //

.header__user-menu {
  display: flex;
  align-items: center;

  @include _992 {
    padding-left: 0;
    margin-left: 15px;
    margin-right: 9px;
  }

  @include _768 {
    margin-left: 16px;
    margin-right: 12px;
  }
}

// ------------------- header__basket ------------------- //

.header__basket {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #f2f8fc;
  border-radius: 10px;
  cursor: pointer;

  @include _992 {
    width: 33px;
    height: 33px;

    background: transparent;
    border-radius: 0;
  }

  @include _768 {
    width: 22px;
    height: 22px;
  }
}

.header__basket-icon {
  display: block;
  width: 27px;
  height: 27px;

  @include _768 {
    width: 22px;
    height: 22px;
  }

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: url(#basketGradient);

      @include _992 {
        fill: #ffffff;
      }
    }
  }
}

.header__basket-counts {
  position: absolute;
  top: -3px;
  right: -5px;
  display: flex;
  align-items: center;
  background: #ffd220;
  border-radius: 50%;
  width: 16px;
  height: 16px;

  &-number {
    width: 100%;
    text-align: center;
    font-family: $font2;
    font-size: 10px;
    line-height: 8px;
    font-weight: 700;
  }

  @include _768 {
    width: 12px;
    height: 12px;

    &-number {
      font-size: 8px;
      line-height: 8px;
    }
  }
}

// ------------------- header__profile ------------------- //

.header__profile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 15px;
  cursor: pointer;
  background: #f2f8fc;
  border-radius: 10px;

  @include _1140 {
    margin-right: 8px;
  }

  @include _992 {
    width: 33px;
    height: 33px;

    background: transparent;
    border-radius: 0;
  }

  @include _768 {
    display: none;
  }
}

.header__profile-icon {
  display: block;
  width: 28px;
  height: 28px;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: url(#userGradient);

      @include _992 {
        fill: #ffffff;
      }
    }
  }
}

// ------------------- header__menu-btn ------------------- //

.header__menu-btn {
  display: none;

  svg {
    width: 32px;
    height: 27px;
  }

  @include _992 {
    display: block;
    width: 32px;
    height: 20px;
    margin: 0 12px;

    svg {
      width: 32px;
      height: 20px;
    }
  }

  @include _768 {
    margin-left: 8px;
    margin-right: 14px;
  }
}

// ------------------- header__menu ------------------- //
.header__nav-wrapper {
  @include _992 {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 50;
    display: none;

    &--show {
      display: block;
    }
  }
}

.header__menu {
  @include _992 {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    max-width: min(330px, calc(100% - 30px));
    width: 100%;
    background: $color--main;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9999;
    background: #ffffff;
  }
}

.header__menu-wrapper {
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: $color--main;

  @include _992 {
    margin-top: 0px;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;
    z-index: 9999;
    background: #ffffff;
  }

}

.header__menu-controls--mobile {
  display: none;
  background: #026db0;

  @include _992 {
    position: absolute;
    left: min(330px, calc(100% - 30px));
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }
}

.header__menu-profile-icon--mobile {
  display: none;

  @include _992 {
    display: block;
    margin-right: 10px;
  }
}

.header__btn--close {
  @include _768 {
    width: 20px;
    height: 20px;
  }

  svg {
    @include _992 {
      width: 20px;
      height: 20px;
    }
  }
}

.header__close-menu-btn {
  &--tablet {
    display: none;

    @include _992 {
      display: block;
    }

    @include _768 {
      display: none;
    }
  }

  &--mobile {
    display: none;

    @include _992 {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
}

.header__nav-list {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include _992 {
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    position: relative;
    z-index: 20;
    padding-bottom: 0;
    padding-top: 0;
  }

  @include _768 {
    padding-top: 10px;

    &.tag-group-selected {
      padding: 0;
    }
  }
}

.header__product-types-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 10px 15px;
}

.header__nav-list-item {
  display: flex;
  align-items: center;
  padding-right: 25px;

  @include _768 {
    display: none;
  }

  a {
    font-family: $font2;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.0125em;
    color: #ffffff;
    display: block;
    padding: 0;
    text-transform: capitalize;
    transition: all 0.1s;

    @include _992 {
      font-weight: normal;
      font-size: 20px;
      line-height: 80px;
      padding: 0;
      text-transform: capitalize;
    }

    @include _768 {
      font-size: 20px;
      line-height: 60px;
      color: #232323;
    }
  }

  &:hover,
  &--active {
    a {
      color: $color--yellow;

      @include _992 {
        font-weight: bold;
      }
    }
  }

  &--icon {
    position: relative;

    &::before {
      position: absolute;
      right: 12px;
      top: 5px;
      width: 9.5px;
      height: 9.5px;
      background: $color--yellow;
      border-radius: 50%;
      content: "!";
      font-weight: bold;
      font-size: 9px;
      line-height: 6px;
      color: #026db0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0;

      @include _992 {
        content: none;
      }
    }
  }
}

.header__openclose-button {
  display: none;

  @include _768 {
    width: 22px;
    height: 22px;
    margin-left: auto;
    display: block;
  }

  &-icon {
    display: block;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.header__blur {
  display: none;

  @include _992 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(35, 35, 35, 0.5);
    height: 100%;

    &.show--search {
      display: block;
    }

    &.show--menu {
      display: block;
      z-index: 999;
    }
  }
}

.header__user-info {
  display: none;

  @include _992 {
    display: flex;
    align-items: center;
    margin: 39px 27px 0 16px;
    padding-bottom: 17px;
    border-bottom: 1px solid #c4c4c4;
  }
}

.header__user-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: $color--main;
}

.mobile-city-selector {
  display: none;

  @include _992 {
    display: block;
  }

  position: relative;
  margin: 33px 27px 0 14px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    margin-left: 5px;

    svg {
      width: 21px;
      height: 29px;
    }
  }

  &__current-city {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-decoration: underline;
    color: $color--main;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 22;
    top: 47px;
    left: 0;
    width: 100%;
    height: 240px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

    &-close-btn {
      position: absolute;
      top: 23px;
      right: 23px;
    }

    &-info {
      padding-left: 34px;
      font-weight: 700;
      font-size: 20px;
      line-height: 60px;
      color: $color--main;
    }

    &-list {
      overflow-y: scroll;

      &-item {
        padding-left: 34px;
        font-weight: 400;
        font-size: 20px;
        line-height: 60px;
        color: #000000;

        &--active {
          color: $color--yellow;
        }
      }
    }
  }

  &__blur {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 21;
    display: none;

    &.show--blur {
      display: block;
    }
  }
}

.menu-catalog {
  position: relative;
  display: flex;
  width: 240px;
  font-family: $font2;
  letter-spacing: 0.0125em;

  @include _992 {
    display: none;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 240px;
    background: #ffd220;
    border: 0;
    padding: 4px 5px;
    cursor: pointer;

    &--active {
      background: $color--main;
      color: #ffffff;
    }

    &-icon {
      width: 19px;
      height: 19px;
      margin-right: 8px;
    }

    &-text {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      margin-top: 2px;
    }
  }

  &__catalog {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9997;
    display: flex;
    flex-direction: row;
  }

  &__product-types {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-basis: 240px;
    max-height: 700px;
    overflow-y: auto;
  }

  &__product-types-item {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 20px;

    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-transform: capitalize;

    background: #006db7;
    color: #ffffff;

    &--active {
      background: #ffd220;
      color: #000000;
    }
  }

  &__filters-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-shrink: 1;
    flex-basis: 920px;
    max-height: 700px;
    padding-left: 50px;
    padding-top: 30px;
    background: #ffffff;
    font-size: 18px;
    line-height: 30px;
  }

  &__filter {
    display: flex;
    flex-direction: column;
    width: 210px;
    margin-bottom: 40px;
  }

  &__filter-name {
    margin-bottom: 15px;
    font-weight: 700;
  }

  &__filter-item {
    font-weight: 400;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__catalog-blur {
    position: absolute;
    top: 100%;
    width: 100vw;
    z-index: 9996;
    background: rgba(1, 1, 1, 0.7);
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.menu-catalog--mobile {
  display: none;
  padding: 10px 20px 0;
  font-family: $font2;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0125em;

  @include _992 {
    display: block;
  }

  &.tag-group-selected {
    padding: 0;
  }

  &__subtypes-container {
    padding: 0 20px;
  }

  &__product-types {
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px;
      text-transform: capitalize;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__back-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: #ffd220;
    outline: none;
    border: none;

    &--icon {
      transform: rotate(180deg);
      margin: 0 20px 0 0;
    }
  }

  &__alcohol-type-name {
    margin: 0 0 10px;
    padding: 10px 50px;
    text-transform: capitalize;
    border-bottom: 1px solid #cbd2d8;
  }

  &__filters-wrapper {
  }

  &__filter {
    padding: 10px 0;
    border-bottom: 1px solid #cbd2d8;

    &-name {
      margin: 0 0 10px;
    }

    &-item {
      margin: 0 0 8px;
      font-weight: 400;

      &:last-of-type {
        margin: 0;
      }
    }
  }
}
