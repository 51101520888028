$font1: "Roboto", sans-serif;
$font2: "Nunito Sans", "sans-serif";

$color--main: #026db0;
$color--main-dark: #00588e;
$color--second: #cacaca;
$color--grey2: #767676;
$color--grey3: #232323;
$color--yellow: #ffc01d;

$button__blue: $color--main;
$button__disabled: $color--second;
$button__yellow: #ffc01d;
$button__grey: #c0c0c0;

$header-puller-size: 28px;
