.clients-main {
    width: 75%;
    @include _992 {
        width: inherit;
    }
}
.clients-breadcrumbs {
    margin-top: -23px;
    margin-left: -3px;
}

.clients-title {
    font-family: 'play';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: $color--main;
    margin-top: 0;
}

.clients-info-wrapper {
    margin-top: 22px;
    margin-bottom: 40px;
    @include _992{
        margin-bottom: 30px;
    }
    @include _768{
        margin-bottom: 20px;
    }
}

.clients-info-subtitle {
    font-family: 'roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
}

.clients-info-text {
    font-family: 'roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}

.clients-target {
    margin-bottom: 20px;
    margin-top: 22px;
    max-width: 660px;
}

.clients-actions {
    display: flex;
    padding-top: 12px;
    max-width:460px;
    justify-content: space-between;
    margin-left: -20px;
}
.clients-actions-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.clients-actions-text {
    font-family: 'play';
    max-width: 131px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: $color--main;
}


.clients-actions-image-wrapper {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    background: $color--main;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clients-instructions {
    max-width: 500px;
    margin-top: 24px;
}

.clients-catalog-link {
    font-family: 'roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 21.7778px;
    line-height: 26px;
    margin-left: -63px;
    text-align: center;
    text-decoration-line: underline;
    color: $color--main;
    display: block;
    @include _992{
        margin-left: 0;
    }
}
