.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 243px;
}

.sidebar__brands {
  min-height: 562px;
  margin-top: 5px;
  flex-grow: 1;

  & + & {
    margin-top: 25px;
  }

  &--positions {
    height: 536px;
    min-height: auto;
  }

  &--fixed-height {
    max-height: 600px;
  }
}

.sidebar__counts {
  margin-bottom: 44px;
}

