a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

input,
textarea,
select,
button {
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
}

embed,
iframe,
img,
object,
video {
  display: block;
  max-width: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $font1;
  min-height: 100vh;
  width: 100%;
}

.overflow {
  overflow: hidden;
}

.wrapper {
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;

  @include _992 {
    padding: 0 15px;
  }

  @include _768 {
    padding: 0 15px;
  }

  &--1180 {
    max-width: 1180px;
  }

  &--1190 {
    max-width: 1190px;
  }

  &--40 {
    @include _992 {
      padding: 0 40px;
    }

    @include _768 {
      padding: 0 15px;
    }
  }

}

.icon {
  &--active {
    cursor: pointer;
  }
}

.center {
  text-align: center;
}
