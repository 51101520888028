.button {
  width: 100%;
  max-width: 141px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 8px;
  font-family: $font2;
  cursor: pointer;
  min-height: 25px;
  background: $color--main;
  color: #ffffff;
  border: 1px solid $color--main;

  button {
    background: inherit;
    border: none;
    font-size: inherit;
    color: inherit;
    cursor: pointer;
  }

  @include _768 {
    padding: 5px 15px;
    font-size: 19px;
    line-height: 22px;
  }

  svg {
    margin-bottom: 5px;
    margin-right: 2px;
  }

  &--blue {
    border: 1px solid $button--blue;
    background: $button--blue;

    &:hover {
      background: $button--yellow;
      color: #000000;
      border: 1px solid $button--yellow;
    }

    &:active {
      background: #666666;
    }
  }

  &--yellow {
    border: 1px solid $button--yellow;
    background: $button--yellow;
    color: #000000;

    &:hover {
      border: 1px solid $color--main;
      background: $color--main;
      color: #ffffff;
    }
  }

  &--disabled {
    border: 1px solid $button--blue;
    color: $button--blue;
    background: transparent;

    &:hover {
      border: 1px solid $button--blue;
      color: $button--blue;
      background: transparent;
    }
  }

  a {
    display: block;
  }

  &--small {
    min-height: 25px;
    font-size: 12px;
    line-height: 8px;
    font-weight: normal;

    @include _768 {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &--big {
    min-height: 36px;
    font-size: 14px;
    line-height: 16px;
  }

  &:active {
    background: $button__grey;
    border: 1px solid $button__grey;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button__link {
  display: block;
}

.tag-button {
  @include _992 {
    border: 1px solid #979797;
    border-radius: 2px;
    color: #979797;
    background-color: transparent;
    min-height: 33px;
    margin: 15px 35px 15px 0;
    padding: 0;
    min-width: 90px;
    overflow: hidden;
  }

  @include _768 {
    font-size: 10px;
    line-height: 12px;
    margin: 7px 20px 7px 0;
  }
}
