.layout__row {
  display: flex;
  justify-content: space-between;
  margin: 0 -22.5px;

  &--desktop {
    @include _992 {
      display: none;
    }
  }

  &--bottom {
    height: 344px;
  }

  &--fixheight {
    height: 365px;
  }
}

.layout__col {
  width: 100%;
  padding: 0 22.5px;
  box-sizing: content-box;

  &--sidebar {
    max-width: 245px;
    flex-shrink: 0;
  }

  &--main {
    flex-shrink: 1;
  }

  &--similarProducts {
    @media (min-width: 991px) {
      max-width: calc(100% - 335px);
    }
  }
}
