.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(1, 1, 1, 0.7);
  overflow-x: hidden;
  overflow-y: auto;

  @include _768 {
    background: #ffffff;
  }
}

.modal__body {
  min-height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.modal__close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
  font-size: 0;
  cursor: pointer;

  svg {
    fill: #b7b7b7;
  }
}

.modal__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);

  @include _768 {
    box-shadow: none;
    width: 100%;
    align-self: flex-start;
  }
}

.modal__inner {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: auto;
  overflow-y: auto;

  &--checkoutPopup {
    max-width: 550px;
  }

  &--citySelector {
    padding: 55px 35px 0;
    background: #ffffff;
    width: 310px;
  }

  &--login {
    padding: 30px 35px 40px;
    background: #ffffff;
    width: 335px;

    @include _768 {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.modal__dialog-title {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 35px;
}

.modal__dialog-btn {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  min-height: 52px;
  width: 100%;
  max-width: 240px;
}

.modal__popup-close-btn {
  position: absolute;
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ffffff;
  right: 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
  top: 2px;
  transform: translate(50%, -50%);
  cursor: pointer;
  z-index: 555;

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    background: $color--main;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  @include _768 {
    display: none;
  }
}

.modal__dialog-link,
.modal__select-option {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: $color--main;
  cursor: pointer;
  font-family: $font2;
}

.modal__select-wrapper {
  padding: 0 0 35px 0;
  cursor: pointer;
}

.modal__select-wrapper {
  position: relative;
}

.modal__select-option {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &--active {
    color: $color--yellow;
  }
}
