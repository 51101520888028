.catalog__paginator {

  @include _768 {
    position: relative;
  }
}

.paginator {
  display: flex;
  justify-content: center;

  @include _768 {
    max-width: 300px;
    margin: 0 auto;
  }
}

.paginator__buttons-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -2.5px;

  @include _768 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.paginator__button {
  min-width: 28px;
  height: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font2;
  padding: 0 2.5px;

  &--disabled {
    cursor: default;
  }

  @include _768 {
    border: none;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;

  }

  &--prev {
    margin-right: 58px;

    @include _768 {
      margin-right: 0;
      position: absolute;
      left: 10px;
      top: 0;
    }
  }

  &--next {
    margin-left: 58px;

    @include _768 {
      margin-left: 0;
      position: absolute;
      right: 10px;
      top: 0;
    }
  }

  &--empty {
    background: none;
    position: relative;
    border: none;
    font-size: 0;
    cursor: default;

    &::before {
      position: absolute;
      content: "....";
      color: #000000;
      font-size: 10px;
      letter-spacing: 2px;
      bottom: 4px;
      left: 50%;
      transform: translateX(-50%);
      right: 0;
    }
  }

  &--current {
    position: relative;
    color: $color--main;
    z-index: 5;
  }
}

.paginator__button-label {
  position: relative;
  z-index: 5;
}
