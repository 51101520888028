.type {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 845px;
  margin: 0 auto;

  @include _992 {
    margin-left: 2px;
  }

  @include _768 {
    margin-top: -40px;
    margin-left: 0;
  }

  .main__row {
    flex-grow: 1;
  }
}

.type__desktop .type__row {
  @include _768 {
    display: none;
  }
}

.type__content {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 351px;
}

.type__mobile {
  display: none;

  @include _992 {
    display: block;
  }
}

.type__breadcrumbs {
  margin-bottom: 1px;

  @include _992 {
    display: block;
    padding-left: 24px;
    margin-top: 24px;
    margin-bottom: 15px;
  }

  @include _768 {
    display: none;
  }
}

.type__info {
  margin-bottom: 20px;
}

.type__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #026db0;
  font-family: $font2;

  &:first-letter {
    text-transform: uppercase;
  }

  @include _992 {
    text-transform: uppercase;
  }

  @include _768 {
    font-size: 17px;
    line-height: 18px;
  }
}

.type__description {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 195px;

  @include _992 {
    font-size: 16px;
    line-height: 24px;
    padding-right: 0;
  }
}

.type__catalog {
  width: 100%;
  display: flex;
  align-items: stretch;

  @include _992 {
    margin-left: 55px;
  }

  @include _768 {
    margin-left: 0;
  }
}

.type__catalog-contols {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @include _992 {
    margin-bottom: 40px;
    margin-left: -4px;
  }

  @include _768 {
    padding: 5px 14px;
    background: #f5f5f5;
    width: auto;
    margin: 0 -15px;
  }
}

.type__page-size-selector {
  @include _992 {
    display: none;
  }
}

.type__filter {
  display: none;

  @include _768 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;

    span {
      margin-right: 7px;
    }
  }
}

.type__filter-mobile {
  margin-left: 29px;
  padding-bottom: 25px;

  @include _768 {
    margin-left: 0;
    padding: 102px 15px 0;
  }

  &--position-filter {
    margin: 0;
    padding: 30px 25px;

    @include _768 {
      padding-top: 82px;
    }
  }
}

.type__filter-mobile-header {
  display: flex;
  align-items: center;
  background: #b7b7b7;
  padding: 20px 30px;
  margin-bottom: 20px;
  flex-direction: row-reverse;
  justify-content: space-between;

  p {
    font-size: 17px;
    line-height: 17px;
    text-transform: uppercase;
    color: white;
    font-family: inherit;
  }

  @include _768 {
    padding: 13px 16px;
    background: $color--main;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;

    &--position-filter {
      margin-bottom: 0;
    }
  }
}

.type__btn--close {
  svg {
    @include _768 {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
}

.type__filter-wrap {
  @include _768 {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #ffffff;
    right: 200px;
    box-shadow: 30px 0 70px rgba(0, 0, 0, 0.7);
    overflow: scroll;
    z-index: 999;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.type__tags {
  display: none;

  @include _992 {
    display: flex;
    justify-content: space-around;
    background-color: #f5f5f5;

    li {
      margin-right: 10px;
    }
  }
}

.type__tags-wrap {
  overflow: hidden;
  max-width: 100%;
}

.type__cards-wrap {
  display: none;

  @include _992 {
    display: block;
    padding: 40px;
  }

  @include _768 {
    display: none;
    padding: 0;
  }

  & .cards__list {
    @include _992 {
      padding: 0 25px 0 34px;
      margin-top: 13px;
    }
  }
}

.type__cards--mobile {
  display: none;

  @include _992 {
    display: block;
    width: auto;
  }
}

.type__filter--desktop {
  @include _992 {
    display: none;
  }
}

.type .type__breadcrumbs {
  @include _992 {
    display: none;
  }
}

.type__cards-wrap .cards__list {
  margin-bottom: 31px;
}
