.main {
  height: 100%;
  margin-bottom: auto;
  padding: 35px 0 52px;

  .wrapper {
    max-width: 1200px;
  }

  &--position {
    padding-top: 65px;
  }

  @include _992 {
    padding: 0;
  }

  &--pt50 {
    padding-top: 50px;
  }

  &--search {
    @include _992 {
      padding-top: 20px;
    }

    @include _768 {
      padding-top: 100px;
    }
  }

  &--profile {
    padding-top: 55px;
  }
}

.main__inner {
  @include _992 {
    padding-top: 152px;
  }

  @include _768 {
    padding-top: 88px;
  }

  &--types {
    @include _992 {
      padding-top: 75px;
    }

    @include _768 {
      padding-top: 70px;
    }
  }

  &--margin {
    margin-bottom: 25px;
  }

  &--positions {
    @include _992 {
      padding-top: 120px;
    }

    @include _768 {
      padding-top: 50px;
    }
  }

  &--search {
    @include _992 {
      padding-top: 120px;
    }

    @include _768 {
      padding-top: 0;
    }
  }
}

.main__row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: 351px;
  margin: 0 -22.5px;

  @include _992 {
    margin: 0;
  }

  &--margin {
    margin-bottom: 19px;

    @include _992 {
      margin-bottom: 0;
    }
  }

  &--desktop {
    @include _992 {
      display: none;
    }
  }
}

.main__side {
  max-width: 245px;
  flex-shrink: 0;
  width: 100%;
  padding: 0 22.5px;
  box-sizing: content-box;

  @include _992 {
    flex-shrink: 1;
  }

  &--narrow {
    width: 17.5%;
    margin: 0 -7px 0 0;
    max-height: 351px;
    overflow: hidden;
  }

  @include _768 {
    width: 0;
  }
}

.main__catalog-wrapper {
  .wrapper {
    @include _992 {
      padding: 0 20px;
    }

    @include _768 {
      padding: 0 15px;
    }
  }
}

.main__catalog {
  max-width: calc(100% - 290px);
  padding: 0 22.5px;
}

.main__content {
  width: 100%;
  padding: 0 22.5px;

  @include _992 {
    padding: 0;
  }

  &--types {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
  }

  &--search {
    margin-top: -17px;
    // TODO: Убрать отрицательные маргины
  }
}

.main__content--position {
  max-width: 1275px;
}

.main__cards-wrapper {
  @include _992 {
    margin-bottom: 33px;
    padding: 17px 25px 0 25px;
  }

  @include _768 {
    margin-bottom: 15px;
    padding: 0;
    margin-top: 35px;
  }
}

.main__positions {
  width: 100%;
  max-width: 856px;
  position: relative;
  padding-top: 25px;

  @include _992 {
    max-width: none;
  }
}

.main__cards {
  width: 82%;
}

.main__wrap {
  display: flex;
}

.main__side--type {
  margin-right: -6px;

  @include _992 {
    display: none;
  }
}

.main__cards-type {
  width: 82.5%;

  @include _992 {
    width: 100%;
    margin-bottom: 39px;
    padding: 0 29px;
  }
}

.main__catalog-wrapper {
  max-width: 100%;
  margin: 0 auto;

  @include _992 {
    max-width: none;
  }
}

.main__counts {
  @include _992 {
    margin: 0 auto 30px;
  }

  @include _768 {
    margin-bottom: 25px;
  }
}

.main__banner {
  margin-bottom: 15px;
}
