.login {
  max-width: 500px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  position: relative;
  cursor: auto;
  width: 100%;
}

.login__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 0;
  cursor: pointer;
}

.login__title {
  font-weight: bold;
  color: $color--main;
  font-family: $font2;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 26px;

  @include _992 {
    font-size: 24px;
    line-height: 35px;
  }
}

.login__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  font-family: $font2;
  margin-bottom: 20px;
}

.login__text-info {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-bottom: 29px;

  &--link {
    text-decoration-line: underline;
    color: $color--main;
    cursor: pointer;
  }
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 250px;

  @include _768 {
    max-width: 300px;
  }
}

.login__input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
  width: 100%;

  .login__error {
    position: absolute;
    bottom: -10px;
    transform: translateY(100%);
    left: 0;
    right: 0;
    color: red;
    text-align: center;
  }

  .login__input {
    display: block;
    padding: 0;
  }

  &--phone {
    flex-wrap: nowrap;
    flex-direction: row;
    width: 230px;
  }

  &--pin {
    margin-bottom: 90px;
  }

  &--pin2 {
    margin-bottom: 45px;
  }
}

.login__input-prefix,
.login__input {
  font-family: $font2;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
}

.login__input-prefix {
  display: block;
  margin-right: 6px;
}

.login__input {
  text-transform: uppercase;
  border: none;
  background: #ffffff;
  outline: none;
  flex-shrink: 1;
  max-width: 180px;
  box-sizing: border-box;
  text-align: left;

  &--number {
    margin-bottom: 15px;
    color: #000000;
  }

  &::placeholder {
    font-size: 15px;
  }
}

.login__input-list {
  display: flex;

}

.login__input-item {
  padding: 0 2px;
  width: 25px;

  input {
    border: none;
    border-bottom: 2px solid $color--main;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    outline: none;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: $color--main;
  }

  &--filled {
    input {
      border: none;
      padding: 0;
    }
  }
}

.login__back-link {
  border: none;
  cursor: pointer;
  background: none;
  align-self: flex-start;
  margin-bottom: 15px;
}

.login__resend-btn {
  border: none;
  cursor: pointer;
  background: none;
  text-align: center;
  margin-bottom: 20px;
  text-decoration: underline;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.login__link {
  font-size: 14px;
  color: $color--main;
  text-decoration: underline;
}

.login__button {
  min-height: 56px;
  font-weight: 700;
  font-size: 21.7778px;
  line-height: 30px;
  text-align: center;
  max-width: 190px;

  &--disabled {
    background: #b0b0b0;
    border: none;
    color: #000000;
  }
}

.login__checkbox-wrapper {
  margin: 0 -10px;
  padding-top: 20px;
  position: relative;

  @include _768 {
    margin: 0;
  }

  input {
    display: none;
  }
}

.login__user-agreement {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  a {
    text-decoration: underline;
    color: $color--main;
  }
}

.login__checkbox {
  width: 12px;
  height: 12px;
  border: 1px solid $color--main;
  box-sizing: border-box;
  display: block;
  margin-right: 10px;
  flex-shrink: 0;
  position: absolute;
  top: 23px;
  left: -15px;

  &--checked {
    background: url("/images/checkbox__mark.png") no-repeat center;
  }

  @include _768 {
    width: 19px;
    height: 19px;
  }
}
