.notification {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 300px;
  background: $color--main;
  color: #ffffff;
  display: none;
  cursor: pointer;
  z-index: 9999999;

  &--show {
    display: block;
  }
}

.notification__title {
  font-size: 24px;
}

.notification__inner {
  padding: 25px;
  display: flex;
  flex-direction: column;
  z-index: 9999999;
}
