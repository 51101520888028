.winery-info {
  max-width: 1220px;
  font-family: $font2;
  width: 100%;
  margin: 65px auto 50px;
  padding: 0 40px;

  @include _992 {
    padding: 88px 15px 30px;
  }

  @include _768 {
    padding: 24px 15px 30px;
  }
  @include _425 {
    padding: 8px 16px 24px;
    margin-bottom: 0;
  }
  &__breadcrumbs {
    margin: 0 0 17px;
  }
  .row-route {
    margin-bottom: 22px;
    @include _320 {
      display: flex;
      align-items: flex-start;
      flex-direction: column-reverse;
      margin-bottom: 20px;
    }
  }
  .row-info {
    @include _320 {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
  .markdown-wrapper {
    @include _425 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      p {
        margin-top: 0;
        margin-bottom: 9px;
      }
    }
  }
  &__name {
    font-family: $font2;
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
    @include _425 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 6px;
    }
  }
  &__address {
    margin: 0 15px 0 0;
    font-family: $font2;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    color: #575757;

    @include _992 {
      margin: 0 0 10px;
    }

    @include _425 {
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #858585;
    }
  }

  &__tooltip {
    &-container {
      position: relative;
      @include _425 {
        margin-bottom: 8px;
      }
    }

    &-content {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(calc(-100% - 5px));
      display: flex;
      padding: 5px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      z-index: 10;

      @include _992 {
        top: 50%;
        transform: translateX(calc(100% + 5px)) translateY(-50%);
      }
      @include _425 {
        background-color: white;
        transform: translateX(calc(-5% + 50px)) translateY(40%);
      }
    }

    &-list-item {
      &:not(:last-of-type) {
        margin: 0 3px 0 0;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__header-rows {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 12px;

    @include _992 {
      flex-direction: column;
      align-items: flex-start;
    }
    @include _425 {
      margin-bottom: 14px;
    }
    @include _320 {
      margin-bottom: 6px;
    }
  }

  &__header-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;

    >div {
      justify-content: space-between;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &__big-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #858585;
      display: flex;
      align-items: center;
      span {
        margin-right: 6px;
      }
      @include _320 {
        margin-bottom: 12px;
      }
    }
    &__button,.winery-info__share-btn {
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #2E6BAB;
    }
    @include _320 {

    }
  }

  &__header-contacts {
    display: flex;
    font-family: $font2;
    align-items: center;
    margin: 0 0 24px;

    @include _992 {
      margin: 0 0 12px;
    }
    @include _425 {
      margin-bottom: 8px;
    }
    @include _320 {
      margin-bottom: 20px;
    }
    svg {
      margin: 0 5px 0 0;
    }

    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin: 0 24px 0 0;
        @include _425 {
          margin-right: 8px;
        }
      }
      @include _425 {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        padding: 4px 12px;
        box-sizing: border-box;
        background: #F5F5F5;
        border-radius: 16px;
    }
      &:last-of-type {
        margin: 0;
      }
    }

    &__website {
      text-decoration: underline;
      @include _425 {
        text-decoration: none;
      }
    }
  }

  &__content-rows {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include _992 {
      flex-direction: column;
    }
  }

  &__positions {
    margin-top: 16px;
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      margin-bottom: 8px;
    }
    &-wine-info {

    }
    .wine-block {
      display: flex;
      align-items: flex-start;
      width: 100%;
      &-icon {
        margin-right: 16px;
        min-width:11px;
        min-height: 38px;
        height: 38px;
        margin-top: 2px;
      }
      &-name {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 6px;
      }
      &-desc {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
      }
      &:not(:last-of-type) {
        margin-bottom: 14px;
      }
    }
  }

  &__catalog {

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      margin-bottom: 8px;
    }
    .catalog__list  {
      margin-bottom: 0;
      height: 291px;
      .swiper-button-prev {
        @include _425 {
          width: 51px !important;
          height: 51px !important;
          top: 50% !important;
          left: 20px !important;
        }
      }
      .swiper-button-next {
        @include _425 {
          width: 51px !important;
          height: 51px !important;
          top: 50% !important;
          right: -1px !important;
        }
      }
    }
    .catalog__item {
      background: #FFFFFF;

      border: 1px solid #F5F5F5 !important;
      border-radius: 4px;
      width: 175px !important;
      height: 291px;
    }
    .catalog__item-inner {
      max-height: 291px;
      max-width: 175px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .catalog__item-region-flag {
      display: none;
    }
    .image__main-img {
      width: 41px;
      height: 155px;
    }
    .catalog__item-image {
      width: 41px;
      height: 155px;
    }
    .catalog__item-img-wrapper {
      border: none;
      width: 41px;
      height: 155px;
      margin-bottom: 14px;
    }
    .catalog__item-info {
      padding: 0;
      justify-content: normal;
      align-self: normal;
    }
    .catalog__item-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      width: 144px;
      color: #000000;
      margin-bottom: 8px;
    }
    .swiper-container {
      width: 370px;
    }
    .catalog__info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .catalog__item-measure,.catalog__item-wp {
      padding: 0;
      margin: 0;
    }
    .catalog__item-wp {
      margin-right: 10px;
      display: none;
    }
    .catalog__item-provider {

    }
    .catalog__info-wrapper--center {
      display: block;      min-height: 0;
      align-items: normal;
    }
    .catalog__item-wp {
       width: auto;
    }
    .catalog__warning-title,.catalog__item-provider {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #858585;
    }
    .catalog__item-price-info {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000000 !important;
    }
    .measurements {
      display: flex;
      align-items: center;
      font-weight: 700 !important;
      font-size: 8px !important;
      line-height: 8px !important;
      color: #313140 !important;
    }
    .quantity {
      display: none;
    }
    .catalog__item-price {
      margin-left: 4px;
    }
    .price__full,.catalog__item-price {
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 16px !important;
      color: #000000 !important;
      .penny {
        font-style: normal !important;
        font-weight: 700;
        font-size: 8px;
        line-height: 8px;
        color: #000000;
        &:before {
          content: "";
        }
      }
    }
  }

  &__column {
    max-width: 50%;
    margin: 0 15px 0 0;

    @include _992 {
      max-width: none;
      margin: 0 0 45px;
    }

    @include _425 {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  &__banner {
    max-width: 496px;
    .swiper-button-prev,.swiper-button-next {
      @include _425 {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .swiper-button-prev {
      @include _425 {
        top: 54% !important;
        left: 10px !important;
      }
    }
    .swiper-button-next {
      @include _425 {
        top: 54% !important;
        right: 10px !important;
      }
    }
    @include _992 {
      max-width: none;
      max-height: 400px;
    }
    .swiper-container {
      width: 100%;
      max-width: 100%;
      max-height: 100vh;
      min-height: 0;
      min-width: 0;
      @include _425 {
        border-radius: 2px;
      }
    }

    .swiper-slide {
      display: block;
      flex-shrink: 0;
      width: auto;
      max-height: 100%;
      height: auto;

      @include _992 {
        max-width: none;
        max-height: 400px;
      }

      picture {
        height: 100%;

        @include _992 {
          width: 100%;
        }
        img {
          @include _425 {
            height: 320px;
          }
        }
      }
    }
    .swiper-pagination-bullets {
      @include _425 {
        bottom: 17px;
      }
      .swiper-pagination-bullet {
        @include _425 {
          width: 10px;
          height: 10px;
          background: #FFFFFF;
          opacity: 0.75;
          margin: 0 10px;
        }
      }
      .swiper-pagination-bullet-active {
        @include _425 {
          width: 10px;
          height: 10px;
          background: #FFFFFF;
          opacity: 1;
          margin: 0 10px;
        }
      }
    }
    .swiper-wrapper {
      max-height: 100%;
      height: 100%;
      display: flex;

      @include _992 {
        width: 100%;
      }
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;

    @include _992 {
      width: 100%;
    }
  }

  &__paragraph-header {
    margin: 0 0 11px;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    color: $color--main;

    &:nth-of-type(n + 2) {
      margin: 30px 0 11px;
    }
    @include _425 {
      font-style: normal;
      margin-top: 7px;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
    }
  }

  &__paragraph {
    margin: 0 0 9px;
    font-family: $font1;
    font-size: 14px;
    line-height: 18px;
  }

  &__services-container {
    margin: 25px 0 0;
    @include _425 {
      margin: 0;
    }
  }

  &__share-btn {
    display: flex;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &-icon {
      margin: 0 4px 0 0;
    }

    @include _992 {
      align-self: flex-end;
    }
    @include _425 {
      padding: 0;
    }
  }

  &__footer {
    margin: 60px 0 0;

    @include _992 {
      margin: 15px 0 0;
    }
  }

  &__service {
    @include _425 {
      box-sizing: border-box;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 2px 2px 8px rgba(7, 63, 101, 0.08);
      border-radius: 4px;
      padding: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }

    &:not(:last-of-type) {
      margin: 0 0 27px;
      @include _425 {
        margin-bottom: 8px;
      }
    }
    &-desc {

    }
    &-top {
      display: flex;
      align-items: center;
      margin: 0 0 8px;
      @include _425 {
        align-items: normal;
        margin-bottom: 0;
      }
    }

    &-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      margin: 0 19px 0 0;
      border: 1px solid $color--main;
      border-radius: 100vmax;
      @include _425 {
        width: 48px;
        height: 48px;
        min-width: 48px;
        border: none;
        background: #F5F5F5;
      }
    }

    &-name {
      font-family: $font2;
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      color: #313140;
      @include _425 {
        font-family: $font2;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }
}
