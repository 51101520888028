@mixin _1280 {
  @media (max-width: 1279px) {
    @content;
  }
}

@mixin _1140 {
  @media (max-width: 1140px) {
    @content;
  }
}

@mixin _992 {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin _768 {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin _576 {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin _520 {
  @media (max-width: 520px) {
    @content;
  }
}

@mixin _480 {
  @media (max-width: 479px) {
    @content;
  }
}

@mixin _425 {
  @media (max-width: 424px) {
    @content;
  }
}

@mixin _375 {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin _320 {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin _240 {
  @media (max-width: 240px) {
    @content;
  }
}
