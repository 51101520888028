.sorting {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  display: flex;
  align-items: center;
}

.sorting__mobile {
  display: none;

  @include _768 {
    display: block;
  }
}

.sorting__desktop {
  @include _768 {
    display: none;
  }
}

.react-select {
  font-size: 12px;
  line-height: 14px;
  color: #767676;
}

.sorting__select {
  max-width: 140px;
}


.react-select-styles {

  &__value-container {
    padding: 0 2px 0 10px !important;
    min-width: 45px!important;
    max-width: 155px!important;
    display: flex;
  }

  &__control {
    border-style: none !important;
    border-width: 0 !important;
    border-radius: 0 !important;
    background-color: inherit !important;
    min-height: 0 !important;
    box-shadow: none;
    min-width: 45px!important;
    max-width: 140px!important;
    width: 100%;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__dropdown-indicator {
    padding: 0 15px 0 0 !important;
  }

  &__menu-list {
    background: #F5F5F5;
    text-transform: uppercase !important;
  }

  &__options {
    background-color: inherit !important;

  }

  &__placeholder {
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }
}

.css-tj5bde-Svg {
  width: 12px;
}

.aria-live-color {
  width: 100%;
}

.sorting__text {
  margin-right: 10px;

  @include _992 {
    font-size: 16px;
    line-height: 19px;
  }
}

.sorting__list {
  display: flex;

  @include _768 {
    position: relative;
    flex-direction: column;
  }
}

.sorting__item {
  color: #000000;
  margin-right: 7px;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;

  @include _992 {
    font-size: 16px;
    line-height: 19px;
    margin-right: 20px;
  }

  @include _768 {
    &--active {
      display: block;
    }
  }

  span {
    margin-left: 3px;
    margin-top: 1px;
    padding-top: 3px;

    svg {
      padding-top: 1px;
    }
  }

  &--active {
    color: #026db0;
  }

  a {
    font-size: inherit;
    color: inherit;
  }
}

.aria-live-color {
  width: 100px;
}

.sorting__item-icon {
  margin-left: 10px;
}
