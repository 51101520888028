.spinner {
  display: none;
}

.nprogress-busy .spinner,
.imgLoading .spinner {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;
  display: block;
  width: 100px;
  height: 100px;
}

.spinner-icon,
.spinner-icon:after {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.spinner-icon {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid $color--main;
  border-right: 1.1em solid $color--main;
  border-bottom: 1.1em solid $color--main;
  border-left: 1.1em solid transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#nprogress .bar {
  @include _992 {
    top: auto;
    bottom: 0;
  }
}

#nprogress .peg {
  display: none;
}

.nprogress-busy,
.imgLoading {
  body {
    overflow: hidden;
  }

  .layout__inner {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #ffffff;
      z-index: 9999;
    }
  }
}
