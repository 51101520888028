.position__offers--inner {
  min-width: 464px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  max-height: 471px;
  overflow: hidden auto;

  @media (max-width: 1100px) {
    min-width: auto;
  }

  a {
    cursor: pointer;

    @include _992 {
      margin-top: 1px;
    }

    @include _768 {
      margin-right: 30px;
    }
  }

  @include _992 {
    width: 100%;
    flex-grow: 1;
    max-width: 512px;
    margin-left: 0;
  }

  @include _768 {
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    max-height: none;
    overflow: inherit;
    min-width: 100px;
  }
}

.position-wrapper {
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
}

.position__title {
  font-family: $font2;

  &:first-letter {
    text-transform: uppercase;
  }

  @include _992 {
    margin-bottom: 0;
  }

  @include _768 {
    padding: 0;
    margin-top: 8px;
    margin-bottom: 11px;
  }

  .position__title-main {
    font-size: 36px;
    line-height: 40px;
    color: #000000;
    margin-bottom: 5px;
    font-weight: normal;

    @include _992 {
      font-size: 26px;
      margin-bottom: 2px;
    }

    @include _768 {
      font-size: 18px;
      line-height: 30px;
      font-weight: bold;
    }
  }

  .position__title-secondary {
    font-size: 24px;
    line-height: 40px;
    color: #000000;
    margin-bottom: 17px;
    font-weight: normal;

    @include _992 {
      font-size: 18px;
      margin-bottom: 13px;
    }

    @include _768 {
      font-size: 14px;
      line-height: 30px;
      opacity: 0.7;
      margin-bottom: 9px;
    }
  }
}

.position__title-second {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;

  & > p:first-child {
    margin-right: 35px;
    font-size: 16px;
    line-height: 19px;
    color: #858585;

    @include _992 {
      font-size: 14px;
      line-height: 16px;
    }

    span {
      color: #000000;
      font-family: $font1;
    }
  }

  & > p:last-child {
    font-size: 16px;
    line-height: 19px;
    color: #858585;
    padding-left: 2px;

    @include _992 {
      font-size: 14px;
      line-height: 16px;
    }

    @include _768 {
      padding-left: 0;
    }

    a {
      text-decoration-line: underline;
      color: #026db0;
      cursor: pointer;
    }
  }
}

.popup__position--views {
  margin-left: auto;
  flex-grow: 1;
  text-align: right;
}

.popup__brand-link {
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;

  span {
    text-decoration-line: underline;
    color: #026db0;
  }
}

.position__info-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 20px;

  @include _992 {
    padding-top: 0;
  }

  @include _768 {
    align-items: center;
    justify-content: center;
  }
}

.position__column-first-wrapper-tablet {
  display: none;
  @include _992 {
    display: block;
    order: -1;
    width: 25%;
  }
  @include _768 {
    display: none;
  }
}
.position__column-first-pc {
  max-width: 385px;
  width: 100%;
  padding-left: 39px;
  flex-shrink: 1;
  flex-direction: column;

  display: flex;
  @include _992 {
    display: none;
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
}
.position__column-first-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.position__column-first {
  margin-left: 98px;
  display: none;

  @include _992 {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
}

.position__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 9px;
  margin-bottom: 25px;

  @include _992 {
    width: auto;
    padding-top: 0;
  }

  @include _768 {
    margin-top: 0;
  }
}

.position__offers--wrapper {
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
  padding-left: 10px;

  @include _992 {
    padding-left: 0;
  }

  @include _768 {
    padding-left: 0;
  }
}

.positon__supplier-wrapper {
  margin: 0 28px 0 21px;

  @include _768 {
    margin: 0;
    padding: 0 15px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #c4c4c4;
  }
}

.position__offers-price {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  font-family: $font2;

  span {
    &:first-of-type {
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
    }

    &:last-of-type {
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      color: #575757;
    }
  }

  @include _768 {
    span {
      &:first-of-type {
        font-weight: 500;
        font-size: 25px;
        line-height: 28px;
      }

      &:last-of-type {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .popup__position--modal & {
    span {
      &:first-of-type {
        font-weight: 500;
        font-size: 18px;
        line-height: 10px;
      }

      &:last-of-type {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .measurements {
    color: #767676;
  }

  .quantity {
    color: #767676;
  }
}

.positon__offer-volume {
  &--desktop {
    color: #666666;
    font-size: 9px;
    line-height: 10px;
  }

  &--mobile {
    display: none;

    span {
      color: #000000 !important;
      opacity: 1 !important;
    }

    @include _768 {
      display: block;
    }
  }
}

.positon__supplier-info {
  width: 32.5%;
  margin-bottom: 20px;
  padding-left: 2px;
  padding-top: 6px;

  @include _768 {
    width: 100%;
    padding-left: 0;
    margin-bottom: 15px;
  }

  .popup__position--modal & {
    padding-top: 12px;
  }
}

.position__offers--info {
  position: relative;
}

.positon__supplier-offers {
  width: 67.5%;
  display: flex;
  flex-direction: column;

  @include _768 {
    width: 100%;
  }
}

.position__offers--info-wp {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;

  .popup__position--modal & {
    justify-content: flex-end;
  }
}

.position__offers-wrap {
  margin-left: auto;
  max-width: 460px;
  margin-right: 7px;
  display: none;

  @include _992 {
    display: flex;
    margin-left: auto;
    order: -1;
    max-width: 512px;
    margin-top: 18px;
    margin-right: 25px;
    flex-direction: column;
  }

  @include _768 {
    display: none;
    order: 0;
    width: 110%;
    max-width: 110%;
    margin: 0 -15px;
  }
}

.position__offers-wrap-phone {
  margin-left: auto;
  max-width: 460px;
  margin-right: 7px;
  display: none;
  @include _768 {
    width: 110%;
    max-width: 110%;
    margin: 0 -15px;
    display: flex;
  }
}

.position__offers-wrap-pc {
  width: 100%;

  @include _992 {
    display: none;
    margin-left: auto;
    order: -1;
    max-width: 512px;
    margin-top: 18px;
    margin-right: 25px;
  }

  @include _768 {
    width: 110%;
    max-width: 110%;
    margin: 0 -15px;
  }
}

.position__reviews-wrap {

  @include _992 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
.position__offers {
  display: flex;
  flex-direction: column;
}

.position__offers-header {
  display: flex;
  align-items: center;
  padding-top: 18px;
  margin-bottom: 29px;

  @include _992 {
    margin-bottom: 5px;
  }

  @include _768 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    padding-top: 20px;
  }

  .popup__position--modal & {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.position__offers-mobile-btn {
  display: none;

  @include _768 {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: $color--main;
  }
}

.position__offers-mobile-btn-icon {
  margin-right: 10px;
}

.position__offers--title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  padding-left: 21px;
  margin-right: 50px;

  @media (max-width: 1101px) {
    margin-right: 15px;
  }

  span {
    color: rgba(1,1,1,0.5);
    margin-left: 6px;

    @include _992 {
      margin-left: 8px;
    }
  }

  @include _992 {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 30px;
    margin-right: 0;
    padding-left: 20px;
  }

  @include _768 {
    font-size: 18px;
    line-height: 21px;
    padding-left: 0;
    margin-left: 17px;
    margin-bottom: 9px;
  }
}

.position__offers-select {
  border: 1px solid #858585;
  box-sizing: border-box;
  border-radius: 1.36px;
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  min-height: 25px;
  min-width: 100px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  position: relative;
  font-family: $font2;

  @include _992 {
    display: none;
  }

  option {
    font-size: 14px;
    line-height: 25px;
    font-family: $font2;
  }
}

.position__offers-select-wp {
  box-sizing: border-box;
  border-radius: 1.36px;
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  min-height: 14px;
  position: relative;
  font-family: $font2;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 24px;

  @include _768 {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 20px;
    align-items: flex-start;
  }
}

.position__offers-select-title-wp {
  position: relative;
}

.position__offers-select-title {
  width: 100%;
  background: #ffffff;
  padding-left: 10px;
  border: none;
  flex-grow: 1;
  min-height: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.position__offers-select-menu {
  position: absolute;
  left: 0;
  bottom: -1px;
  display: flex;
  flex-direction: column;
  transform: translate(0, 100%);
  box-sizing: content-box;
  background: #ffffff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
  padding-top: 10px;
  width: auto;
  white-space: nowrap;

  @include _768 {
    box-shadow: none;
    left: auto;
    width: auto;
    position: static;
    transform: none;
    bottom: auto;
    margin-right: 0;
  }
}

.position__offers-select-list {
  margin-bottom: 2px;
  width: 100%;
}

.position__offers-select-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.position__offers-actvie-element {
  cursor: pointer;
}

.position__filter-submit-btn {
  display: block;
  border: none;
  color: #ffffff;
  background: $color--main;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  height: 20px;
  min-height: auto;
  box-sizing: border-box;
  padding: 0;

  @include _768 {
    display: none;
  }
}

.position__volumes-list {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100px;
  transform: translate(-50%, 100%);
  border: 1px solid #858585;
}

.position__volumes-list-item {
  min-height: 25px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #858585;
    box-sizing: border-box;
    background: #ffffff;
  }

  &--active {
    background: red;
  }
}

.position__option-item {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 6px;
  box-sizing: border-box;

  &--empty {
    margin-bottom: 10px;
  }
}

.position__option-label {
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  font-family: $font2;
  position: relative;

  @include _768 {
    font-size: 20px;
    line-height: 33px;
  }
}

.position__option-checkbox {
  min-width: 12px;
  max-width: 12px;
  height: 12px;
  border: 0.5px solid #cacaca;
  border-radius: 1px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--active {
    background: url("/images/checkbox-mark.svg") no-repeat center;

    @include _768 {
      background: url("/images/checkbox-mark.svg") no-repeat center / 80% auto;
    }
  }

  @include _992 {
    margin-top: 0;
  }

  @include _768 {
    margin-top: 10px;
    max-width: 19px;
    max-height: 19px;
    width: 19px;
    height: 19px;
  }

  svg {
    transform: translateY(-1px);
  }

  &--checked {
    border: 0.5px solid $color--main;
    border-radius: 1px;
  }
}

.position__offers--info {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: $font2;
  width: 35%;

  @include _992 {
    margin-top: 10px;
  }

  @include _768 {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
}

.position__offers--supplier {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #026db0;
  cursor: pointer;
  display: block;
  margin-bottom: 15px;

  & > p:first-letter {
    text-transform: uppercase;
  }

  .popup__position--modal & {
    margin-bottom: 5px;
  }

  @include _768 {
    margin-right: 30px;
    width: 100%;
    margin-bottom: 2px;
  }
}

.position__offers-reviews {
  visibility: hidden;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #666666;
  margin-bottom: 9px;

  @include _992 {
    font-size: 12px;
    line-height: 15px;
  }
}

.position__offers-goods {
  display: block;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #026db0;
  max-width: 100px;

  @include _992 {
    font-size: 12px;
    line-height: 15px;
  }

  @include _768 {
    max-width: none;
  }
}

.position__price--wrap {
  display: flex;
  justify-content: space-between;
  margin-left: 45px;
  width: 100%;

  @include _768 {
    width: 100%;
    margin-left: 0;
    margin-top: 6px;
  }
}

.position__price {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include _768 {
    width: 100%;
  }
}

.position__price-value {
  span {
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;

    &:last-of-type {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      opacity: 0.5;
    }

    @include _992 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.position__price--item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @include _768 {
    margin-bottom: 0;
  }

  p:first-of-type {
    font-size: 14px;
    line-height: 16px;
  }

  p:last-of-type {
    font-size: 9px;
    line-height: 10px;
    color: #666666;
    margin-top: 4px;
    padding-left: 3px;
  }
}

.position__price-second-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  line-height: 13px;
  width: 92px;
  text-align: start;
  border: 0;
  cursor: pointer;
  border: none !important;
  font-family: $font1;
  max-height: 36px;

  @include _992 {
    width: 113px;
    max-height: 39px;
    height: 39px;
    margin-right: 4px;
    font-size: 14px;
    line-height: 16px;
  }

  @include _768 {
    padding: 5px 8px;
    margin-right: 0;
  }

  span {
    line-height: 0;

    @include _992 {
      margin-right: 3px;
    }

    @include _768 {
      margin-right: 1px;
    }
  }

  svg {
    fill: #000000;
  }

  &:hover {
    svg {
      fill: #ffffff;
    }
  }
}

.position__price-third-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #026db0;
  font-size: 11px;
  line-height: 13px;
  width: 92px;
  text-align: start;
  color: white;
  border: 0;
  cursor: pointer;

  svg {
    margin-top: 8px;
  }
}
.position__column-first-layout {
  display: flex;
  flex-direction: column;
  max-width: 59%;
  flex-shrink: 2;
  padding-top: 33px;

  @media (max-width: 1250px) {
    width: 50%;
  }

  @include _992 {
    display: none;
    padding-top: 0;
  }
  @include _768 {
    display: flex;
    width: 100%;
    max-width: none;
  }
}
.position__image {
  margin-left: 13px;
  margin-top: 21px;

  @include _992 {
    order: -2;
    max-width: 100%;
    display: flex;
    align-items: center;
    margin: 28px 0 0 23px;
    padding: 0 0 0 14px;
  }

  @include _768 {
    max-height: 260px;
    max-width: 290px;
    margin-top: 15px;
    padding: 0 35px 0 0;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    object-fit: contain;

    @include _992 {
      max-height: 540px;
    }

    @include _768 {
      width: 100%;
      height: 100%;
    }
  }

  & .catalog__item-region-flag {
    display: none;

    @include _768 {
      display: block;
      position: absolute;
      top: 32px;
      left: 2px;
      width: 36px;
      height: 24px;
    }
  }
}

.position__image-wrapper {
  width: 270px;
  height: 579.14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1150px) {
    width: 200px;
  }

  @include _992 {
    height: 510px;
    width: 175px;
  }
  @include _768 {
    height: 272px;
  }
  picture, img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center top;

    @include _992 {
      object-position: center center;
    }
  }
}
.position__overall-rating-phone {
  display: none;
}
.position_reviews-quantity {
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  line-height: 19.04px;
  font-family: "play";
  color: #000000;
  margin-top: 9.7px;
  margin-left: 3px;
}
.position__properties--wrapper {
  max-width: 360px;
  width: 100%;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    background: #026db0;
    color: white;
    margin-bottom: 20px;
  }

  @include _992 {
    max-width: 100%;
    background: #f5f9fc;
  }

  @include _768 {
    padding: 5px 16px;
  }
}

.position__properties--columns {
  display: flex;
  flex-direction: column;

  &--tablet {
    display: none;

    @include _992 {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 8px 40px;
      position: relative;
      display: flex;

      &::after {
        position: absolute;
        width: 0.5px;
        background: #b0b0b0;
        left: 50%;
        transform: translateX(-50%);
        top: 8px;
        bottom: 8px;
        content: "";
      }
    }

    @include _768 {
      display: none;
    }
  }

  &--mobile {
    display: none;

    @include _768 {
      display: flex;
      padding-top: 8px;
    }
  }
}

.position__properties--column {
  display: flex;
  flex-direction: column;

  @include _992 {
    padding: 0 18px;
  }
}

.position__properties--column1 {
  @include _992 {
    height: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 100%;
  }

  @include _768 {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.position__properties--tablet-column {
  @include _992 {
    width: 50%;
    min-height: 15px;
  }
}

.position__properties--line {
  border-bottom: 1px solid black;
}

.position__properties--item {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  font-family: $font2;
  min-height: 36px;

  @include _992 {
    width: 50%;
  }

  span {
    color: #000000;
    font-size: 14px;
    line-height: 18px;
  }

  div {
    font-size: 14px;
    line-height: 18px;

    color: #000000;
    @include _992 {
      font-size: 16px;
    }
  }

  div:first-letter {
    text-transform: uppercase;
  }

  div:first-of-type {
    width: 120px;

    @include _992 {
      padding-left: 16px;
      width: 140px;
    }

    @include _768 {
      width: 135px;
      padding-left: 0;
    }
  }

  div:nth-of-type(2),
  a {
    color: #000000;
    width: 165px;

    @include _992 {
      width: 225px;
      padding-left: 41px;
    }

    @include _768 {
      padding-left: 9px;
      width: 190px;
    }
  }

  @include _992 {
    height: 0;
  }
}

.position__properties-tablet-item {
  display: flex;
  align-items: center;
  min-height: 36px;
  width: 50%;
  padding-left: 17px;
  padding-right: 5px;

  @include _768 {
    width: 100%;
    padding-left: 0;
  }
}

.position__properties-tablet-item-key {
  font-size: 16px;
  color: $color--main;
  width: 140px;
  flex-shrink: 0;

  &:first-letter {
    text-transform: uppercase;
  }
}

.position__properties-tablet-item-value {
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  @include _768 {
    max-width: 50%;
  }
}

.position__properties--item-link {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
}

.position__properties--list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.position__properties--item-links {
  width: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  @include _768 {
  }

  a {
    text-decoration: underline;
  }

  &:not(:last-of-type) {
  }

  &--lowcase {
    text-transform: lowercase;
  }
}

.position__description-wrapper {
  display: none;
  @include _992 {
    width: 100%;
    display: block;
  }
}

.position__description-pc-wrapper {
  margin-top: -22px;
  @include _992 {
    display: none;
  }
}

.position__description-wrap {
  width: 93%;
  background: #f5f9fc;
  padding: 43px 47px 60px 33px;
  font-family: $font2;
  margin-bottom: 20px;

  @include _992 {
    min-width: 100%;
    width: auto;
    box-sizing: border-box;
    padding-top: 40px;
  }

  @include _768 {
    padding: 0 40px 10px 18px;
    margin: 0;
  }

  h3 {
    display: flex;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 15px;
    width: 100%;
    font-weight: 700;
    align-items: center;

    @include _992 {
      margin-bottom: 10px;
    }

    @include _768 {
      cursor: pointer;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 4px;
      padding-top: 4px;
      color: black;
    }

    span {
      display: none;
      margin-left: 5px;

      @include _768 {
        display: block;
      }

      svg {
        margin-bottom: 1px;
      }
    }
  }

  p,
  div {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    @include _992 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 30px;
    }

    @include _768 {
      font-size: 14px;
      line-height: 18px;
      padding-right: 18px;
      padding-top: 5px;
      margin-bottom: 0;
      padding-bottom: 12px;
    }
  }
}

.position__description-first {
  @include _768 {
    display: none;
    background: white;
    margin: 0 -40px;
    padding: 0 1px 0 40px;
  }

  &-open {
    @include _768 {
      display: block;
    }
  }
}

.position__description-wrap {
  word-break: break-word;
}

.position__description-second {
  font-family: $font1;
  font-weight: 400;

  @include _768 {
    display: none;
    background: white;
    margin: 0 -40px;
    padding: 0 40px;
  }

  &-open {
    @include _768 {
      display: block;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 16px;

    li {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}

.position__description-third {
  @include _768 {
    display: none;
    background: white;
    margin: 0 -40px;
    padding: 0 40px;
  }

  &-open {
    @include _768 {
      display: block;
    }
  }
}

.position__description-title-open,.position__description-second-title-open,.position__description-third-title-open {
  color: #000000 !important;
}

.position__tags-wrap {
  width: 60%;

  @include _992 {
    width: 100%;
  }
}

.position__tags {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.position__tag-button {
  min-height: 37px;
  color: #979797;
  background: #f5f5f5;
  margin: 15px 35px 15px 0;
  padding: 0;
  min-width: 90px;
  overflow: hidden;
  border: 0;

  @include _768 {
    max-width: 93px;
    font-size: 9px;
    line-height: 11px;
    margin: 0 5px;
    padding: 8px 5px;
  }
}

.position__properties-column-item {
  display: flex;
}

.position__properties--separator {
  display: none;
  position: absolute;
  left: 50%;
  width: 1px;
  height: 83%;
  background: #b0b0b0;

  @include _992 {
    display: block;
  }

  @include _768 {
    display: none;
  }
}

.position__link-mobile {
  display: none;

  @include _768 {
    display: flex;
    font-size: 12px;
    line-height: 14px;
    color: #707070;
    margin-top: 25px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 13px;
      margin-right: 3px;
    }
  }
}
.position__wrap .type__breadcrumbs {
  padding-bottom: 14px;
  padding-left: 4px;

  @include _992 {
    padding: 0;
    margin-top: 30px;
  }
}

.position__reviews {
  margin-top: 30px;

  @include _992 {
    margin-top: -4px;
  }
  @include _768 {
    display: flex;
    justify-content: center;
  }
}

.position__reviews--overall-rating-wrapper {
  margin-top: -9px;
  margin-left: -5px;
  @include _992 {
    margin-top: 4px;
    margin-left: 2px;
  }
  @include _768 {
    margin-top: 0;
    margin-right: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
  }
}

.position__reviews--overall-rating {
  color: $color--main;
  font-weight: bold;
  font-size: 42.9116px;
  line-height: 18px;
  text-align: center;
  font-family: "play";
  @include _768 {
    margin-left: 12px;
  }
}

.position__reviews--overall-stars {
  display: none;
  @include _768 {
    display: flex;
    margin-top: 14px;
    margin-left: 15px;
  }
}
.position__reviews--overall-rating-star {
  margin-right: 3px;
}

.position__reviews--reviews-quantity {
  margin-top: 2px;
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 10px;
  line-height: 17.88px;
  font-family: "play";
  @include _992 {
    font-size: 14px;
    line-height: 19.04px;
    margin-left: -1px;
    margin-top: 1px;
  }
  @include _768 {
    margin-top: -2px;
    margin-left: 10px;
  }
}

.position__reviews--rating-filter-wrapper {
  margin-top: 16px;
  margin-left: -4px;
  display: none;

  @include _992 {
    margin-top: -4px;
    margin-right: 24px;
  }
  @include _768 {
    margin-top: 8px;
    margin-left: 38px;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none;
  }
}

.position__reviews--rating-wrapper {
  display: flex;
  margin-top: 4px;
  margin-bottom: 6.9px;
  @include _992 {
    margin-top: 0;
    margin-bottom: 9px;
  }
  @include _768 {
    margin-bottom: 4.5px;
  }
}

.position__reviews--stars {
  margin-right: 6.26px;
  width: 90px;
  @include _992 {
    width: 88px;
  }
  @include _768 {
    width: 79px;
  }
}

.position__reviews--rating-star {
  margin-left: 1.62px;
  margin-right: 1.62px;
  @include _992 {
    margin-left: 3.5px;
    margin-right: 0;
  }
  @include _768 {
    margin-left: 1.6px;
  }
}

.position__reviews--rating-star-none {
  visibility: hidden;
  margin-left: 1.62px;
  margin-right: 1.62px;
  @include _768 {
  }
}

.position__reviews--rating-range {
  height: 8.05px;
  width: 78.67px;
  background: #f5f9fc;
  border-radius: 1.78798px;
  margin-top: 4px;
  margin-right: 8.05px;
  @include _992 {
    margin-right: -4px;
    margin-top: 4px;
  }
}

.position__reviews--rating-range-filler {
  height: 100%;
  background: #ffc01d;
  border-radius: 1.78798px;
}

.position__reviews--rating-count {
  font-family: "play";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-decoration: underline;
  @include _992 {
    margin-left: 16px;
  }
}

.position__similar-wrap {
  display: flex !important;
  min-width: 100% !important;
}

.position__catalog-wrap {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .swiper-container {
    padding-bottom: 5px;
  }

  @include _992 {
    max-width: 100% !important;
  }

  .swiper-slide {
    position: relative;
  }

  .catalog__item {
    height: 315px;
    position: relative;

    &:hover {
      .catalog__item-link {
        position: static;
        top: auto;
        left: auto;
        right: auto;
        width: 100%;
      }
    }
  }
}

.position__similar-wrap .items {
  min-width: 17.5% !important;
  width: 17.5% !important;
  margin-right: 90px !important;

  @include _992 {
    display: none !important;
  }
}

.position__slider .swiper-wrapper {
  flex-direction: row !important;
  max-width: 100% !important;
}
.position__slider .swiper-slide {
  max-height: 350px !important;
  width: 215px !important;

  @include _768 {
    width: 159px !important;
    margin-left: 18px !important;
  }
}

.position__slider .catalog__item {
  width: 215px !important;
  margin-bottom: 0;

  @include _768 {
    width: 156px !important;
    border-bottom: none !important;
    border: 1px solid #e5e5e5 !important;
  }
}

.position__catalog-list .items__list {
  border: none !important;

  @include _768 {
    width: auto;
    margin: 0 -15px;
  }
}

.position__similar-itmes-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 18px;

  @include _992 {
    display: none;
  }
}

.position__catalog-wrap .catalog__item-inner {
  &:hover {
    @include _768 {
      box-shadow: none !important;
    }
  }

  @include _768 {
    flex-direction: column;
    padding: 10px 10px;
    max-height: 240px;
    min-height: 240px;
  }
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: none !important;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: none !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  display: none;

  @include _992 {
    display: flex;
    width: 55px !important;
    height: 55px !important;
    top: 48% !important;

    svg {
      width: 55px;
      height: 55px;
    }
  }

  @include _768 {
    width: 44px !important;
    height: 38px !important;
    top: 44% !important;
    right: 0 !important;
  }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  display: none;

  @include _992 {
    display: flex;
    width: 55px !important;
    height: 55px !important;

    svg {
      width: 55px;
      height: 55px;
    }
  }

  @include _768 {
    width: 44px !important;
    height: 38px !important;
    top: 44% !important;
    left: 0 !important;
  }
}

.position__catalog-wrap .catalog__item-image {
  @include _768 {
    height: 129px;
  }
}

.position__catalog-wrap .catalog__item-region-flag {
  @include _768 {
    width: 28px;
    height: 20px;
    left: 12px;
    top: 12px;
  }
}

.position__catalog-wrap .catalog__item-title {
  @include _768 {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 12px;
  }
}

.position__catalog-wrap .catalog__item-wp {
  @include _768 {
    flex-direction: row;
    margin-bottom: 0;
  }
}

.position__catalog-wrap .catalog__item-measure {
  @include _768 {
    font-size: 11px !important;
    line-height: 13px;
    margin-bottom: 15px;
  }
}

.position__catalog-wrap .catalog__item-provider {
  @include _768 {
    font-size: 11px !important;
    line-height: 13px;
  }
}
.price__full {
  display: flex;
  align-items: flex-start;
}
.penny {
  &:before {
    content: ".";
  }
}
.position__catalog-wrap .catalog__item-price span:first-of-type {
  @include _768 {
    font-size: 19px;
    line-height: 22px;
  }
}

.position__catalog-wrap .catalog__item-price span .measurements {
  @include _768 {
    font-size: 13px;
    line-height: 15px;
  }
}

.position__catalog-wrap .catalog__item-price span .quantity {
  @include _768 {
    font-size: 13px;
    line-height: 15px;
  }
}

.position__catalog-wrap .catalog__item-img-wrapper {
  @include _768 {
    justify-content: center;
  }
}

.position__catalog-wrap .catalog__item-price-info {
  @include _768 {
    text-align: center;
  }
}

.position__reviews--user-review-wrapper {
  margin-top: 19px;
  @include _992 {
    margin-top: 22px;
  }
  @include _768 {
    display: flex;
    margin-top: 32px;
    display: none;
  }

  display: none;
}

.position__reviews--user-review-title {
  font-family: "play";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  @include _992 {
    font-size: 14.91px;
    line-height: 13.31px;
  }
  @include _768 {
    font-size: 14px;
    line-height: 18px;
    max-width: 102px;
    margin-top: 4px;
    margin-left: -32px;
  }
}

.position__reviews--user-icon {
  margin-top: 6px;
  margin-right: 7.17px;
  @include _992 {
    margin-top: 12px;
    margin-left: 3px;
  }
  @include _768 {
    margin-left: 10px;
    margin-right: 2px;
    display: none;
  }
}
.position__reviews--user-icon-phone {
  display: none;
  @include _768 {
    margin-left: 20px;
    margin-right: 2px;
    display: block;
  }
}

.position__reviews--user-review {
  display: flex;
}

.position__reviews--user-review-stars {
  margin-top: 13px;
  display: flex;
  @include _992 {
    margin-top: 18px;
  }
  @include _768 {
    margin-left: 4px;
    margin-top: 9px;
  }
}

.position__reviews--star-gray-16 {
  cursor: pointer;
  margin-right: 3.8px;
  @include _992 {
    margin-right: 5.8px;
  }
  @include _768 {
    display: none;
  }
}

.position__reviews--star-gray-21 {
  cursor: pointer;
  display: none;
  margin-right: 5.8px;
  @include _768 {
    display: block;
  }
}

.position__reviews--star-gray-vivino-14 {
  margin-top: -1px;
  margin-left: 2px;
  @include _768 {
    display: none;
  }
}
.position__reviews--star-gray-vivino-17 {
  margin-top: -1px;
  margin-left: 2px;
  display: none;
  @include _768 {
    display: block;
  }
}
.position__reviews--star-red-14 {
  margin-left: 2.5px;
  @include _768 {
    display: none;
  }
}
.position__reviews--star-red-17 {
  margin-left: 2.5px;
  display: none;
  @include _768 {
    display: block;
  }
}
.position__reviews--social-wrapper {
  margin-top: 71px;
  display: flex;
  @include _992 {
    display: block;
    margin-top: -4px;
    margin-right: 21px;
    margin-bottom: 25px;
  }
  @include _768 {
    display: flex;
    margin-top: -5px;
    margin-right: 18px;
    justify-content: center;
    justify-items: center;
  }
}
.position__reviews--social {
  display: flex;
  @include _768 {
    margin-left: 15px;
  }
}
.position__reviews--social-icon {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 6px;
  padding: 3px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  @include _768 {
    width: 30.45px;
    height: 30.45px;
  }
}

.position__reviews--vivino-wrapper {
  display: flex;
  margin-top: 8px;
  margin-left: 9px;
  @include _992 {
    margin-top: 15px;
    margin-left: 0;
  }
  @include _768 {
    margin-left: 6px;
    margin-top: 8px;
  }
}

.position__community--wrapper {
  margin-top: 27px;
  @include _992 {
    padding: 0 25px;
  }
  @include _768 {
    padding: 0 1px;
  }
}

.position__community--header {
  display: flex;
  @include _992 {
    margin-top: 11px;
    margin-left: 1px;
  }
}

.position__community--header-title {
  font-family: "play";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: $color--main;
  @include _768 {
    margin-left: -2px;
    font-size: 18px;
    line-height: 21px;
  }
}

.position__comunity--quantity {
  font-family: "play";
  font-style: normal;
  font-weight: bold;
  font-size: 13.7939px;
  line-height: 16px;
  margin-left: 12px;
  margin-top: 7px;
  color: #ffc01d;
  @include _768 {
    font-size: 12px;
    line-height: 14px;
  }
}

.position__community--review-wrapper {
  margin-top: 16px;
  margin-left: 2px;
}

.position__community--review-header {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.position__community--review-user-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  font-family: "play";
  @include _992 {
    margin-top: -2px;
    margin-left: 2px;
  }
  @include _768 {
    margin-top: 8px;
    margin-left: -2px;
  }
}

.position__community--review-date {
  margin-right: -12.5px;
  font-family: "play";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #858585;
  @include _992 {
    margin-right: -2px;
    font-size: 14px;
  }
  @include _768 {
    display: none;
  }
}

.position__community--review-stars {
  margin-top: 7px;
  @include _992 {
    margin-top: 6px;
  }
  @include _768 {
    margin-top: 7.8px;
  }
}

.position__community--review-star {
  margin-right: 4.2px;
  @include _768 {
  }
}

.position__community--review-description {
  margin-top: 6px;
  font-family: "play";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  padding-bottom: 16px;
}

.position__community--all-reviews {
  float: right;
  font-family: "play";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-top: -16px;
  margin-right: -6px;
  text-decoration-line: underline;
  color: $color--main;
  @include _992 {
    font-size: 15px;
    margin-top: -14px;
    margin-right: 0;
  }
}

.position__community--form-wrapper {
  margin-top: 59px;
}

.position__community--form-header {
  display: flex;
  @include _768 {
    justify-content: space-between;
  }
}

.position__community--form-title {
  font-family: "play";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
  color: $color--main;
  @include _768 {
    font-size: 18px;
    margin-top: 4px;
  }
}

.position__community--form-stars {
  margin-left: 36px;
  margin-top: 2px;
  @include _768 {
    margin-left: 0;
  }
}

.position__community--form-star {
  margin-right: 4.91px;
  cursor: pointer;
  @include _768 {
    margin-right: 0;
    margin-left: 4.91px;
  }
}

.position__community--form-area {
  margin-top: 14px;
  margin-left: 2px;
  width: 463px;
  padding: 0 15px;
  height: 88px;
  background: #f5f5f5;
  border-radius: 2px;
  border: none;
  resize: none;
  @include _992 {
    width: 100%;
    height: 88px;
  }
}

.position__community--form-agreement {
  display: flex;
  margin-top: 9px;
}

.position__community--form-checkbox {
  width: 12px;
  height: 12px;
  border: 1px solid $color--main;
  box-sizing: border-box;
  margin-top: 0;
  margin-left: 2px;
  @include _768 {
    margin-right: 8px;
    margin-top: -4px;
  }
}

.position__community--form-agreement-text {
  font-family: "play";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  margin-left: 10px;
  margin-top: -1px;
  @include _992 {
    font-size: 12px;
    margin-top: -2px;
  }
  @include _768 {
    font-size: 10px;
    line-height: 18px;
    margin-left: 0;
    margin-top: -5px;
  }
}

.position__community--form-agreement-link {
  color: $color--main;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
}

.position__community--popup {
  p {
    margin: 15px 0;
  }

  ul {
    padding-left: 15px;
    list-style: circle;

    li {
      margin: 10px 0;
    }
  }
}

.position__community--form-btn {
  color: #000000;
  margin-top: 27px;
  margin-right: -4px;
  background: #ffc01d;
  border: none;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 92px;
  height: 36px;
  @include _992 {
    margin-top: 4px;
    margin-right: 5px;
    margin-bottom: 47px;
  }
  &:hover {
    background: $color--main;
    color: #ffffff;
  }
}

.position__reviews--wrapper-tablet {
  display: none;
  @include _992 {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
  }
  @include _768 {
    display: none;
  }
}

.position__reviews--wrapper-phone {
  display: none;
  @include _768 {
    display: block;
  }
}

// -------------------------------- position__share-btn -------------------------------- //

.position__share-wrapper {
  margin-left: auto;
  position: relative;
}

.position__share-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.position__share-label {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  position: relative;
}

.position__share-btn-icon {
  margin-right: 5px;

  svg {
    width: 15px;
    height: 12px;
    fill: #b0b0b0;
  }
}

.position__share-list {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0 4px 10px rgb(0 0 0 / 15%);
  padding: 5px;
  z-index: 5;
  align-items: center;
  display: none;

  &--show {
    display: flex;
  }
}

.position__share-item {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.position__share-social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
}

.popup__position--offers-column .position__offers--inner {
  margin-left: 0 !important;
}

.position__offers-list {
  width: 100%;
  max-width: 100%;
}

.positon__supplier-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  @include _768 {
    flex-direction: column;
  }

  &:not(:last-of-type) {
    border-bottom: 1.16872px solid #c4c4c4;
  }
}

.popup__position--offers-column {
  .positon__supplier-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

.position__filters-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;

  @include _768 {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }

  &--show {
    @include _768 {
      display: block;
    }
  }

  .position__option-checkbox {
    @include _768 {
      margin-top: 0;
    }
  }
}

.position__filter-header {
  cursor: pointer;
  font-family: $font2;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;

  @include _992 {
    font-size: 14px;
    line-height: 16px;
  }

  @include _768 {
    font-size: 18px;
    line-height: 21px;
  }

  svg {
    margin-right: 5px;
    fill: #000000;
    width: 12px;
    height: 9px;

    @include _768 {
      width: 20px;
      height: 19px;
      margin-top: 6px;
    }
  }
}

.position__filter-btn-wrapper {
  @include _768 {
    display: none;
  }
  &--mobile {
    display: none;

    @include _768 {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}

.position__button {
  display: flex;
  line-height: 15px;
  padding: 0;
  width: 141px;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &--mobile {
    min-height: 68px;
    width: calc(50% - 5px);
    max-width: 200px;
    font-size: 19px;
  }

  @include _768 {
    &--reset {
      background: #ffffff;
      color: $color--main;
    }
  }

  svg {
    margin-bottom: 0;
  }
}

.position__bottom-wrapper {
  padding-top: 36px;
}

.position__row {
  display: flex;
  margin: 0 -15px;

  @include _992 {
    margin: 0;
  }

  &--margin {
    margin-bottom: 25px;
  }

  &--body {
    margin-bottom: 25px;

    @include _768 {
      flex-direction: column;
    }
  }

  &--head {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  &--desktop {
    @include _992 {
      display: none;
    }
  }

  &--social {
    display: flex;
    justify-content: flex-end;
  }

  &--description {
    @include _992 {
      display: flex;
      flex-direction: column;
    }

    @include _768 {
      margin: 0 -15px;
      width: auto;
    }
  }

  &--reviews {
    @include _992 {
      margin-bottom: 18px;
    }
  }

  &--tablet {
    display: none;

    @include _992 {
      display: flex;
      justify-content: space-between;
    }
  }

  &--specs {
    @include _992 {
      margin: 0 -40px;
    }

    @include _768 {
      margin: 0 -15px;
    }
  }
}

.position__col {
  display: flex;
  padding: 0 15px;
  box-sizing: border-box;

  @include _992 {
    padding: 0;
  }

  &--left {
    flex-grow: 1;

    @media (max-width: 1100px) {
      width: 570px;
      flex-shrink: 0;
    }

    @include _992 {
      width: auto;
      flex-shrink: 1;
    }

    @include _768 {
      flex-shrink: 0;
    }
  }

  &--right {
    width: 465px;
    flex-shrink: 0;
    flex-direction: column;

    @media (max-width: 1100px) {
      width: 100%;
      flex-shrink: 1;
    }
  }

  &--columns {
    flex-direction: column;
  }

  &--description {
    padding-top: 42px;

    @media (max-width: 1100px) {
      width: 570px;
    }

    @include _992 {
      width: auto;
    }

    @include _992 {
      order: 2;
      padding-top: 48px;
    }
  }
}

.position__product-info {
  display: flex;
  padding-top: 50px;

  @include _768 {
    padding-top: 0;
  }
}

.position__offers-reviews-wrapper {
  padding-top: 9px;

  @include _992 {
    width: 100%;
    max-width: 512px;
    flex-shrink: 0;
  }

  @include _768 {
    margin: 0 -15px;
    max-width: none;
    width: auto;
  }
}

.reviews {
  font-family: $font2;
  width: 100%;
}

.reviews__header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @include _768 {
    margin-bottom: 20px;
  }
}

.reviews__title {
  color: $color--main;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-right: 2px;

  @include _992 {
    margin-right: 6px;
  }

  @include _768 {
    font-size: 18px;
    line-height: 25px;
  }
}

.reviews__count {
  color: $color--yellow;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}

.reviews__item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  width: 100%;

  @include _992 {
    margin-bottom: 9px;
  }
}

.reviews__item-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.reviews__item-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #858585;
  margin-left: auto;
  display: block;
}

.reviews__mark-wrapper {
  margin-bottom: 10px;

  @include _992 {
    margin-bottom: 7px;
  }
}

.reviews__mark-list {
  display: flex;
  align-items: center;

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
}

.reviews__item-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 35px;

  @include _992 {
    line-height: 20px;
    margin-bottom: 28px;
  }
}

.reviews__show-all {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: $color--main;
  border: none;
  background: none;
  display: block;
  margin-left: auto;
  margin-right: -5px;

  @include _992 {
    font-size: 15px;
    line-height: 18px;
  }
}

// -------------------------- ProductAddReviewForm  -------------------------- //

.position__add-review-wrapper {
  @include _992 {
    width: 100%;
  }

  @include _768 {
    padding: 0 15px;
  }
}

.review-form__header {
  display: flex;
  align-items: center;
  font-family: $font2;
  margin-bottom: 23px;

  @include _768 {
    justify-content: space-between;
  }
}

.review-form__title {
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 18px;
  margin-right: 25px;

  @include _768 {
    font-size: 18px;
    line-height: 20px;
  }
}

.review-form__text {
  background: #f5f5f5;
  border-radius: 2px;
  border: none;
  width: 100%;
  min-height: 85px;
  margin-bottom: 3px;
  padding: 15px;

  @include _992 {
    margin-bottom: 7px;
  }
}

.review-form__checkbox-wrapper {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @include _992 {
    margin-bottom: 4px;
  }

  @include _768 {
    margin-bottom: 12px;
  }

  input {
    display: none;
  }

  label {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;

    @include _768 {
    }
  }

  a {
    color: $color--main;
    text-decoration: underline;
  }
}

.review-form__checkbox {
  width: 12px;
  height: 12px;
  border: 1px solid $color--main;
  box-sizing: border-box;
  display: block;
  margin-right: 10px;
  flex-shrink: 0;

  &--checked {
    background: url("/images/checkbox__mark.png") no-repeat center;
  }

  @include _768 {
    width: 19px;
    height: 19px;
  }
}

.review-form__btn {
  margin-left: auto;
  min-height: 36px;
  max-width: 92px;
  font-weight: bold;
  box-sizing: border-box;

  @include _768 {
    margin: 0 auto;
    max-width: 132px;
  }
}

.position__tablet-specs-wrapper {
  @include _992 {
    min-width: 100%;
  }

  @include _768 {
    margin: 0;
    width: auto;
  }
}

.position__rating-wrapper {
  @include _992 {
    margin-bottom: 41px;

    @include _768 {
      margin-bottom: 27px;
    }
  }
}

.position__offers-empty {
  padding: 15px 21px;
  color: $color--main;
  font-family: $font2;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  @include _992 {
    padding: 15px 17px;
  }
}
