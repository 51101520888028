.positions {
  padding-top: 20px;
}

.positions__inner {
  display: flex;

  @include _768 {
    padding-top: 39px;
  }
}

.positions__sidebar {
  padding-top: 25px;

  @include _992 {
    display: none;
  }
}

.positions__breadcrumbs {
  margin-bottom: 10px;
}

.positions__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #026db0;
  margin-bottom: 15px;
}

.positions__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -11px;
}

.positions__item {
  width: 25%;
  padding: 0 11px;
  margin-bottom: 25px;
  justify-content: center;
  display: flex;
  flex-shrink: 0;

  @media (max-width: 1190px) {
    width: 33.33%;
  }

  @include _992 {
    width: 33.33%;
  }

  @include _768 {
    width: 50%;
  }

  @include _480 {
    width: 100%;
    margin-bottom: 15px;
  }
}

.position__item-inner {
  min-height: 104px;
  padding: 16px 10px;
  display: block;
  position: relative;
  min-width: 197.5px;
  width: 100%;

  &--supplier {
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .positions__item-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      font-family: $font2;
      color: #000000;
      text-transform: capitalize;
    }

    .positions__image-wp {
      width: 100%;
      height: 50px;
      margin-bottom: 15px;
    }

    .positions__img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    &:hover {
      border: 1px solid #ffffff;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }

  &--position {
    background: #63423f;

    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }

    .positions__item-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;
      color: #ffffff;
      position: relative;
      z-index: 5;
      margin-bottom: 2px;
    }

    .positions__count {
      font-size: 12px;
      line-height: 14px;
      text-transform: lowercase;
      color: #ffffff;
      position: relative;
      z-index: 5;
    }

    .positions__back {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
    }

    .positions__img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 50% 50%;
    }
  }
}
