.banner {
  .swiper-pagination {
    display: block;
  }

  .swiper-pagination-bullet {
    background: $color--main;
    opacity: 1;

    &-active {
      background: $color--yellow;
    }
  }
}
