.position__rating-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
}

.rating {
  font-family: $font2;
  display: flex;
  flex-direction: column;

  @include _992 {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    position: relative;
    height: 135px;
  }

  @include _768 {
    flex-direction: column;
    height: auto;
    align-items: center;
  }
}

.rating__mark-wrapper {
  margin-bottom: 14px;

  @include _992 {
    width: 165px;
  }

  @include _768 {
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 12px;
  }
}

.rating__mark {
  color: $color--main;
  font-weight: 700;
  font-size: 43px;
  line-height: 43px;

  @include _992 {
    margin-bottom: 0;
  }

  @include _768 {
    margin-bottom: 17px;
  }
}

.raiting__chart-wrapper {
  padding-left: 5px;
  margin-bottom: 15px;

  @include _992 {
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: stretch;
  }

  @include _768 {
    margin-bottom: 8px;
  }
}

.rating__list {
  @include _992 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
  }

  @include _768 {
    padding: 0;
  }
}

.rating__reviews-count {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #000000;
  text-decoration-line: underline;

  @include _992 {
    font-size: 14px;
    line-height: 14px;
  }

  @include _768 {
    text-align: center;
  }
}

.rating__row {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  @include _992 {
    margin: 0;
  }

  @include _768 {
    margin-bottom: 5px;
  }
}

.rating__col {
  &--center {
    margin: 0 10px;

    @include _992 {
      margin-left: 12px;
      margin-right: 20px;
    }
  }
}

.rating__bar {
  width: 78px;
  height: 8px;
  background: #f5f9fc;
  border-radius: 1.78798px;
  position: relative;
  overflow: hidden;
}

.rating__filled-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 1.78798px;
  background: $color--yellow;
}

.rating__mark-value {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  color: #000000;
}

.rating__add-from {
  padding-left: 5px;
  margin-bottom: 25px;

  @include _992 {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    padding-left: 0;
  }

  @include _768 {
    position: static;
    order: 4;
    display: flex;
    align-self: flex-start;
    align-items: center;
  }
}

.raiting__add-from-title {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  margin-bottom: 10px;

  @include _992 {
    font-size: 15px;
    line-height: 13px;
  }

  @include _768 {
    margin-bottom: 0;
    max-width: 40%;
    line-height: 20px;
  }
}

.raitng__user-pic {
  width: 26px;
  height: 26px;
  margin-right: 10px;

  @include _768 {
    height: 34px;
    width: 34px;
  }
}

.raitng__info {
  display: flex;
  align-items: center;
}

.raiting__liks-wrapper {
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  padding-left: 5px;

  @include _992 {
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4px;
    padding-right: 15px;
    width: 165px;
  }

  @include _768 {
    flex-direction: row;
    width: auto;
    padding: 0;
    margin-bottom: 34px;
    align-items: flex-end;
  }
}

.ratins__seach-links {
  display: flex;
  align-items: center;
  margin-right: 15px;

  @include _992 {
    margin-bottom: 12px;
  }

  @include _768 {
    margin-bottom: 0;
  }
}

.rating__seach-item {
  li {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c4c4c4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 6px;
    padding: 3px;
    width: 25px;
    height: 25px;

    @include _768 {
      width: 30px;
      height: 30px;
    }
  }
}

.raiting__vivino {
  display: flex;
  align-items: center;
}

.raiting__vivino-icon {
  margin-left: 1px;
  width: 50px;
  height: 17px;

  @include _768 {
    margin-left: 8px;
    margin-right: -3px;
    width: 58px;
    height: 18px;
  }
}

.rating__vivino-stars {
  display: flex;
}

.position__vivino-link {
  display: block;
}

.rating__vivino-stars {
  display: flex;
  align-items: center;
}

.rating__vivino-star-item {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 2.5px;
}

.rating__vivino-star {
  position: absolute;
  left: 0;
  top: 0;
}
