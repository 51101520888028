.markdown-wrapper {
  * {
    margin: revert;
    padding: revert;
    list-style: revert;
    color: #313140;
  }

  h1 {
    font-size: 24px;
    line-height: 33px;
  }

  h2 {
    font-size: 20px;
    line-height: 25px;
  }

  h3 {
    font-size: 18px;
    line-height: 21px;
  }

  h4 {
    font-size: 16px;
    line-height: 19px;
  }

  h5 {
    font-size: 14px;
    line-height: 18px;
  }

  h6 {
    font-size: 12px;
    line-height: 14px;
  }
}
