
#map {
  height: 90vh;
  min-height: 600px;
  width: 100%;

  @include _992 {
    height: calc(100vh - 102px);
  }

  @include _768 {
    height: calc(100vh - 57px);
  }
}

.popup__map {
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  color: #232323;
  width: 240px;
  min-width: 1px;

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__share-items {
    display: none;

    &.show {
      position: absolute;
      top: -40px;
      right: -5px;
      z-index: 5;
      display: flex;
      background: #fff;
      box-shadow: 0 4px 10px rgb(0 0 0 / 15%);
      padding: 5px 0 0 5px;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 3px;
    }
  }

  &__share-wrapper {
    position: relative;
  }

  &__share-btn {
    border: none;
    outline: none;
    background: inherit;
  }

  .icon-share {
    content: "";
    background: url("/images/icon-share.svg");
    width: 19px;
    height: 19px;
    display: flex;
    cursor: pointer;
  }

  &-picture {
    img {
      max-width: 100%;
      object-fit: fill;
    }
  }

  &-service {
    position: relative;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin: 0 0 5px;
    }
  }

  &-services {
    padding: 8px 0 15px;

    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #FFFFFF;
      border: 1px solid $color--main;
      margin-right: 8px;
      cursor: pointer;
    }

    .popup__map-service-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &-description {
    display: flex;
    flex-direction: column;
    padding: 9px 11px 10px;
  }

  &-name {
    font-weight: 700;
    font-size: 16px;
    max-width: 160px;

    &:hover {
      color: $color--yellow;
    }
  }

  &-hours {
    font-weight: 600;
    font-size: 12px;
    padding: 15px 0 13px;
    display: flex;
  }

  &-tel {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
  }

  &-site {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;

    p {
      text-decoration: underline;
    }
  }

  &-close-btn {
    position: absolute;
    top: 9px;
    right: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    color: #b0b0b0;

    &:hover {
      color: black;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.icon {
  padding-right: 8px;

  &.icon-telephone:before {
    content: "";
    background: url("/images/icon-telephone.svg");
    width: 13px;
    height: 13px;
    display: flex;
    @include  _425 {
      display: none;
    }
  }

  &.icon-site:before {
    content: "";
    background: url("/images/icon-site.svg");
    width: 13px;
    height: 13px;
    display: flex;
    @include  _425 {
      display: none;
    }
  }
}

#map {
  @include _992 {
    margin-top: 102px;
  }

  @include _768 {
    margin-top: 57px;
  }
}

.ymaps-2-1-79 {
  &-copyrights-pane {
    display: none;
  }
  &-islets_serp-popup {
    @include _425 {
      display: none !important;
    }
  }

  &-controls__toolbar {
    @include _425{
      margin: 0 !important;
    }
  }

  &-searchbox__button-cell {

    &.ymaps-2-1-79-_focused {
      padding-right: 4px !important;

      .ymaps-2-1-79-searchbox-button {
        border-top-left-radius: 8px !important;
        height: 34px !important;
        border-bottom-left-radius: 8px !important;
        background-color: #F8D44E !important;
        margin: 2px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        &::after {
          width: 36px;
          height: 16px;
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          padding-right: 10px;
          content: 'Найти';
        }
      }

    }
  }
  &-searchbox-list-button {
    @include _425 {
      display: none !important;
    }
  }
  &-searchbox-button {
    @include _425 {
      background-color: transparent !important;

    }
  }
  &-_focused {
    @include _425 {
      outline: none !important;
      box-shadow: none !important;
    }
  }
  &-searchbox-input {

    @include _425 {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  }

  &-searchbox-input__input {
    height: 38px !important;
    border: 1.1875px solid #979797;
    @include _425 {
      font-family: 'Nunito Sans' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  &-suggest-item-0{
    @include _425 {
      margin-top: 0 !important;
    }
  }
  &-search__suggest {
    @include _425 {
      margin-top: 4px  !important;
      padding: 12px  4px!important;
    }
    > ymaps {
      @include _425 {
        display: flex;
        max-height: 280px !important;
        height: auto;
        flex-direction: column;
        overflow: auto;
        scroll-behavior: smooth;
      }
    }
  }
  &-searchbox__normal-layout {
    width: 100% !important;
    @include _425 {
      background: white !important;
    }
    &::after {
      @include _425 {
        border-radius: 8px !important;
        box-shadow: 0px 0px 24px rgba(7, 63, 101, 0.1), 0px 0px 16px rgba(7, 63, 101, 0.15) !important;
      }
    }
  }

  &-searchbox-button {
    height: 38px !important;
    display: flex !important;

  }

  &-searchbox-button-text {
    background: url("/images/icon-search.svg") no-repeat center;
    text-indent: -9999px;
    width: 30px;
    box-sizing: border-box;
    display: flex;
    @include _425 {
      background: url("/images/icon-search-mobile.svg") no-repeat center;
    }
  }

  &-zoom {
    display: none !important;
  }

  &-controls__toolbar_right {
    display: none;
  }

  &-controls__bottom {
    display: none;
  }

  &-_hidden-text {
    display: none !important;
  }

  &-balloon__content {
    padding: 0 !important;
  }

  &-map ymaps {
    border-radius: 4px;
    @include  _425 {
      border-radius: 8px;
    }
    .dropdown-menu {
      &.exposed {
        transition: bottom 0.3s ease-in;
        bottom: calc(100vh - #{$header-puller-size} - 57px) !important;
        margin-top: -2px !important;
        height: 100vh !important;
        border-radius: 0 !important;
        z-index: 3;
        .dropdown-list {
          margin-top: 56px !important;
          display: block;
          height: calc(81vh);
        }

      }
      &.default {
        transition: bottom 0.5s ease-in;
        bottom: 277px !important;
      }
      &.closed {
        transition: bottom 0.5s ease-in;
         bottom: 0px !important;
      }
      &.bigview {
        transition: bottom 0.5s ease-in;
        bottom: calc(543px) !important;
      }
      background: #FFFFFF;
      box-shadow: 0 3.26787px 6.53575px rgba(0, 0, 0, 0.16);
      padding: 0;
      margin: 58px 0 0 -10px;
      width: 406px;
      z-index: 3;

      box-sizing: border-box;
      .dropdown-header {
        display: none;
        @include _425 {
          display: block;
          height: $header-puller-size !important;
          background: url("/images/puller.svg") center no-repeat;
        }
      }
      .dropdown-list {
        overflow: auto;
        scroll-behavior: smooth;
        height: calc(90vh);
        min-height: calc(600px - 72px);

      }

      @include _992 {
        height: calc(100vh - 102px - 72px);
      }

      @include _768 {
        height: calc(100vh - 57px - 72px);
      }
      @include _425 {
        margin-top: 0;
        height: 100vh;
        position: relative !important;
        left: 0 !important;
        border-top-left-radius: 16px ;
        border-top-right-radius: 16px ;
        width: 100vw;
      }
      a {
        cursor: pointer;
      }

      li {
        width: 100%;
      }

      ymaps {
        width: 100%;
      }
    }
  }

  &-controls__control_toolbar:nth-child(2) {
    position: absolute !important;
    top: 0;
    left: 0;
    padding: 18px 22px 20px 30px;
    background: #D9D9D9;
    margin: -15px 0 0 !important;
    width: 406px;
    box-sizing: border-box;
    border-radius: 0;

    &.cover {
      margin-top: 36px !important;
      transition: all 0.2s ease-in;
    }
    @include _425 {
      padding: 0;
      z-index: 4;
      border-radius: 8px !important;
      background: white !important;
      margin: 16px 0 0 16px !important;
      width: calc(100vw - 30px);
    }
  }

  &-controls__control_toolbar:nth-child(3) {
    position: absolute !important;
    top: 2px;
    left: 0;
    @include _425 {
      top: calc(100vh - #{$header-puller-size} - 57px);
    }
    ymaps {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .btn {
        background: #FFFFFF;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
        width: 14px;
        height: 40px;
        border: none;
        outline: none;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0;
        z-index: 4;
        @include _425{
          display: none;
        }

        .caret {
          display: flex;
          justify-content: center;

          &:after {
            content: "";
            background: url("/images/icon-arrow.svg");
            width: 5px;
            height: 8px;
            display: flex;

          }
        }
      }
    }
  }
}

.winery {
  &-status {
    padding-right: 5px;
    border-right: 1px solid #707070;

    &-divider {
      display: none;
    }
    &.active {
      color: #549C2C;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    &.disabled {
      color: #ff0000;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    @include _425 {
      border-right: none;
      padding-right: 8px;
      &-divider {
        display: block;
        height: 2px;
        width: 2px;
        border-radius: 50%;
        background: #858585;
      }
    }
  }

  &-hours-detail {
    padding-left: 5px;
    color: #858585;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    @include _425 {
      padding-left: 8px;
    }
  }
}

.map__preview {
  &-winery {
    font-family: $font2;
    padding: 24px 21px 0;
    animation-name: appear;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    &:hover {
      cursor: pointer;
      background: #F5F5F5;
      @include _425 {
         .map__preview-tel,.map__preview-site {
           background-color: white;
         }
      }
    }
    @include _425 {
       padding: 11px 15px;
    }
  }
@keyframes appear {
  from {
    opacity: .2;
  }
  to {
    opacity: 1;
  }
}
  &__bigview {
    animation-name: appear;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;

    &:hover {
      background-color: #F5F5F5;
      .map__preview-tel,.map__preview-site {
        background-color: white;
      }
      .map__preview__bigview-tags {
        li {
          border: 1px solid #D7D7D7;
        }
      }
    }
    padding: 16px;

    &-picture {
      margin-bottom: 4px;
    }

    &-picture img{
      width: 358px;
      height: 208px;
      object-fit: cover;
    }

    &-name {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 2px;
    }
    &-description  {
       > p {
         max-width: none;
         margin-bottom: 0;
       }
      margin-bottom: 4px;
    }
    &-tags {
      max-height: 60px;
      overflow: hidden;
      padding: 8px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 4px;

      li {
        justify-content: center;
        box-sizing: border-box;
        display: flex;
        width: auto !important;
        padding: 4px 12px;
        margin-bottom: 4px;
        border: 1px solid #F5F5F5;
        border-radius: 12px;
        align-items: center;
        &:not(:last-child) {
          margin-right: 4px;
        }
         img {
           width: 20px;
           margin-right: 4px;
           object-fit: fill;
           opacity: .50;
         }
        p {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #858585;
        }
      }
    }
    .map__preview-hours {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &-address {
      margin-bottom: 4px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      width: 100%;
      color: #858585;
    }
    &-service {
      padding: 12px 0;
      margin-bottom: 12px;
      .map__preview-service-item {
        margin-right: 24px;
        cursor: pointer;
      }

    }
    &-contacts {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
      span,p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #000000;

      }
    }
  }

  &-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    max-width: 179px;
  }

  &-address {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    max-width: 179px;
    color: #232323;
    @include _425 {
      font-family: 'Nunito Sans';
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }

  &-hours {
    font-weight: 600;
    font-size: 12px;
    padding: 4px 0 13px;
    display: flex;
    align-items: center;
    @include _425 {
      padding-top: 0px;
      padding-bottom: 5px;
    }
  }
  &-service {
   display: none;
   &-item {
     cursor: pointer;
     font-family: 'Nunito Sans';
     font-style: normal;
     font-weight: 600;
     font-size: 14px;
     line-height: 16px;
     color: #2E6BAB;
   }
   @include _425 {
     display: flex;
     margin-top: 16px;
     align-items: center;
     justify-content: space-between;
   }
 }
  &-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-desc {
    display: flex;
    flex-direction: column;
    max-width: 236px;
    margin-right: 17px;
  }
  &-picture {
    @include _425 {
      justify-content: flex-end;
      display: flex;
    }
  }
  &-picture img {
    width: 150px;
    height: 112px;
    object-fit: fill;
    @include _425 {
      width: 106px;
      height: 106px;
    }
  }

  &-contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 13px;
    @include _425 {
      margin-top: 24px;
      justify-content: normal;
    }
  }

  &-tel {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-right: 32px;

    @include _425 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-right: 11px;
      color: #000000;
      padding: 4px 6px;
      background: #F5F5F5;
      border-radius: 16px;
    }
  }

  &-site {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    p {
      text-decoration: underline;
      @include _425 {
        text-decoration: none;
      }
    }

    @include _425 {
      padding: 4px 5px;
      background: #F5F5F5;
      border-radius: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
  &-description {
    @include _425 {
      margin: 0 !important;
    }
  }
  &-divider {
    height: 1px;
    width: 100%;
    background: #C4C4C4;
    margin: 9px 0 0 0;
    padding: 0;
    border: 0;
    @include _425 {
      display: none;
    }
  }
  &-block {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  &-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #858585;
    text-align: left;
  }
  &-description {
    margin-right: 10px;

    a:hover {
      color: $button__yellow;
    }
  }
}

.dot__icon-content {
  background: #ffffff;
  color: #313140;
  text-align: center;
  padding: 3px;
  width: 100px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.2);
}

.cluster-balloon {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 400px;
  max-height: 450px;
  min-width: 380px;

  &__content {
    display: flex;
    height: 100%;
    overflow-y: auto;
  }

  &__list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    min-width: 140px;
  }

  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    color: #b0b0b0;

    &:hover {
      color: black;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .popup__map-close-btn {
    display: none;
  }
}

.dots-list {
  display: flex;
  flex-direction: column;
  width: 120px;
  padding: 5px 0 10px 0;

  line-height: 18px;
  gap: 5px;

  &__item {
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    padding: 0 10px;
    word-wrap: normal;

    &:hover:not(&--active) {
      color: $button__yellow;
    }

    &--active {
      background: #F5F5F5;
    }
  }
}
