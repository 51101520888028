.slider__wrapper {
  margin: 0 45px;
  box-sizing: border-box;
  padding: 45px 0;
  position: relative;

  @include _768 {
    margin: 0 5px;
    padding: 20px 0;
  }
}

.slider__item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  padding: 20px;

  @include _992 {
    padding: 10px;
  }

  @include _768 {
    max-width: 156px;
  }
}

.slider__item-region-flag {
  position: absolute;
  left: 20px;
  top: 10px;
  width: 58px;
  height: 39px;

  @include _768 {
    max-height: 19px;
    max-width: 28px;
  }
}

.slider__item-image {
  margin-bottom: 5px;
  height: 125px;

  @include _768 {
    max-height: 125px;
    max-width: 28px;
  }
}

.slider__item-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  font-family: $font2;
  margin-bottom: 25px;

  @include _768 {
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 10px;
  }
}

.slider__item-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: $font2;
  margin-bottom: 15px;
}

.slider__item-measure {
  font-size: 18px;
  line-height: 18px;
  color: #000000;

  @include _768 {
    font-size: 10px;
    line-height: 11px;
  }
}

.slider__item-provider {
  font-weight: bold;
  font-size: 22.4737px;
  line-height: 26px;
  text-align: center;
  color: #026db0;

  @include _768 {
    font-size: 10px;
    line-height: 11px;
  }
}

.slider__item-price-info {
  font-weight: normal;
  font-size: 20.9267px;
  line-height: 25px;
  text-align: center;
  color: #000000;

  @include _768 {
    font-size: 11px;
    line-height: 14px;
  }
}

.slider__item-price {
  font-size: 38px;
  line-height: 45px;

  @include _768 {
    font-size: 18px;
    line-height: 22px;
  }
}

.slider__btn {
  width: 70px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  z-index: 20;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &--prev {
    left: -35px;

    svg {
      transform: translateX(-5px);
      margin-bottom: 7px;
      margin-left: 5px;
    }

    @include _768 {
      left: -5px;
    }
  }

  &--next {
    right: -35px;

    svg {
      transform: translateX(5px);
      margin-bottom: 7px;
      margin-right: 6px;
    }
    @include _768 {
      right: -5px;
    }
  }

  @include _768 {
    width: 34px;
    height: 34px;
      svg {
        height: 40px;
        width: 10px;
      }

  }

  @include _320 {
    display: none;
  }
}

.swiper-pagination {
  display: none;
  width: 100%;
  height: 15px;
  bottom: 10px;
  top: auto;

  @include _992 {
    display: block;
  }
}
