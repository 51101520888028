.filters {
  margin-top: -13px;
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  padding-right: 50px;

  @include _992 {
    padding-right: 0;
  }

  @include _768 {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 35px;
  }
}

.filters .filters__item {
  margin-bottom: 22px;

  @include _992 {
    max-width: 180px;
    margin-bottom: 26px;
  }

  @include _768 {
    margin-bottom: 53px;
    max-width: none;
  }

  &:last-of-type {
    margin-bottom: 34px;

    @include _768 {
      margin-bottom: 48px;
    }
  }
}

.filters__mobile {
  display: none;

  @include _992 {
    display: block;
    min-width: 180px;
    margin-top: 15px;
  }
  @include _768 {
    display: none;
  }
}

.filters__button {
  margin-bottom: 11px;
  display: flex;
  order: 0;
  padding-left: 3px;
  line-height: 15px;
  font-weight: 700;

  svg {
    margin-bottom: 0;
  }

  @include _992 {
    order: -1;
    height: 29px;
    max-width: 165px;
    margin-bottom: 24px;
    justify-content: space-around;
    font-size: 14px;
    line-height: 16px;
  }

  @include _768 {
    height: 67px;
    order: 0;
    font-size: 19px;
    line-height: 23px;
    max-width: 145px;
    margin: 0;
  }

  &--right {
    @include _768 {
      margin-left: auto;
    }
  }
}

.filters__button--blue {
  font-weight: 700;

  @include _992 {
    max-width: 180px;
    height: 40px;
    font-size: 15px;
    line-height: 18px;
  }

  @include _768 {
    height: 67px;
    font-size: 19px;
    line-height: 23px;
    max-width: 145px;
  }
}

.filter__header {
  cursor: pointer;
  font-family: $font2;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: none;
  background: none;
  width: 100%;
  padding: 0;
  text-align: left;

  @include _992 {
    font-size: 14px;
    line-height: 16px;
  }

  @include _768 {
    font-size: 18px;
    line-height: 21px;
  }

  svg {
    margin-right: 5px;
    fill: #000000;
    width: 12px;
    height: 9px;

    @include _768 {
      width: 20px;
      height: 19px;
      margin-top: 6px;
    }
  }

  &--disabled {
    color: rgba($color: #000000, $alpha: 0.3);

    svg {
      fill: rgba($color: #000000, $alpha: 0.3);
    }
  }
}

.filter__search-input {
  margin-bottom: 10px;

  input {
    width: 100%;
  }
}

.filter__body {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;

  @include _768 {
    height: 22px;
    margin-bottom: 12px;
  }
}

.slider__track,
.slider__range {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #cacaca;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #026db0;
  z-index: 2;
}

.slider__range-input {
  width: 100%;
  pointer-events: none;
  position: absolute;
  height: 0;
  outline: none;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &--left {
    z-index: 3;
  }

  &--right {
    z-index: 4;
  }

  &::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  &::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
}

.filter__footer {
  display: flex;
  justify-content: space-between;

  @include _768 {
    padding-left: 4px;
  }
}

.filter__input-wrapper {
  position: relative;

  &::before {
    left: 5px;
    top: 50%;
    position: absolute;
    transform: translateY(-55%);
    z-index: 10;
    display: block;
    font-weight: normal;
    font-size: 11.5769px;
    line-height: 15px;
    color: #313140;

    @include _768 {
      font-size: 19px;
      line-height: 24px;
    }
  }

  &--left::before {
    content: "от";
  }

  &--right::before {
    content: "до";
  }
}

.filter__input {
  border: 0.5px solid #cacaca;
  border-radius: 1px;
  font-weight: normal;
  font-size: 11.5769px;
  line-height: 15px;
  color: #313140;
  max-width: 85px;
  padding: 4px 0 4px 20px;

  @include _768 {
    max-width: 137px;
    font-size: 19px;
    line-height: 24px;
    padding: 6px 0 6px 30px;
  }
}

.filters__list {
  @include _768 {
    width: 100%;
    margin-bottom: -18px;
  }
}

.filter__options-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  div {
    width: 100%;
  }
}

.filter__option-title:not(first-of-type) {
  margin-top: 12px;
  margin-bottom: 2px;
  font-family: $font2;
}

.filter__option-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  @include _992 {
    width: 100%;
    align-items: center;
  }

  @include _768 {
    width: 50%;
    align-items: center;
  }
}

.filter__option-label {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  font-family: $font2;
  position: relative;

  @include _992 {
    font-size: 14px;
    line-height: 24px;
  }

  @include _768 {
    font-size: 17px;
    line-height: 33px;
  }
}

.filter__option-checkbox {
  min-width: 12px;
  max-width: 12px;
  height: 12px;
  border: 0.5px solid #cacaca;
  border-radius: 1px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;

  @include _992 {
    margin-top: 0;
  }

  @include _768 {
    width: 19px;
    height: 19px;
    max-width: 19px;
    max-height: 19px;
    flex-shrink: 0;
    margin: 0 10px 0 0;
  }

  svg {
    transform: translateY(-1px);
  }

  &--checked {
    border: 0.5px solid $color--main;
    border-radius: 1px;
  }
}

.filters__button--wrapper {
  @include _768 {
    display: flex;
  }
}

.filter__show-more {
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;

  @include _768 {
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 15px;
  }

  ul {
    display: flex;
    align-items: center;
    margin-right: 5px;
    transform: translateY(1px);

    @include _768 {
      transform: translateY(-4px);
    }

    li {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $color--main;

      &:not(:last-of-type) {
        margin-right: 2px;
      }
    }
  }

  .filter__show-more-btn {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    font-family: $font2;
    color: $color--main;
    cursor: pointer;
    border: none;
    background: none;

    @include _992 {
      font-size: 14px;
      line-height: 24px;
    }

    @include _768 {
      font-size: 19px;
      line-height: 19px;
    }
  }

  &--disabled {
    cursor: default;

    .filter__show-more-btn {
      color: $color--second;
      cursor: default;
    }

    ul {
      li {
        background: $color--second;
      }
    }
  }

  &--active {
    cursor: pointer;

    .filter__show-more-btn {
      cursor: pointer;
    }
  }
}

.filter__btn-icon {
  @include _768 {
    display: none !important;
  }
}
