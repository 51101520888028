.contacts-wrap {
    margin-top: -10px;
    width: 75%;
    @include _992 {
        width: inherit;
    }
}

.contacts-main {
    @include _992 {
        margin-left: -200px;
    }
    @include _768 {
        margin-left: -80px;
    }
}
.contacts-breadcrumbs {
    margin-top: -13px;
    margin-left: -3px;
}
.contacts-title {
    font-family: 'play';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: $color--main;
    margin-top: 0;
}
.contacts-info-text {
    font-family: 'roboto';
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    max-width: 620px;
    margin-top: 55px;
    margin-bottom: 20px;
    color: #000000;
}
.contacts-email-link {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-decoration-line: underline;
    color: $color--main;
}
.contacts-catalog-link {
    font-family: 'roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 21.7778px;
    margin-top: 40px;
    line-height: 26px;
    margin-left: -63px;
    text-align: center;
    text-decoration-line: underline;
    color: $color--main;
    display: block;
    @include _992{
        margin-left: 0;
    }
}
