.popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(1, 1, 1, 0.7);
  overflow: hidden;
  padding-top: 260px;
  height: calc(100vh - calc(100vh - 100%));

  @include _768 {
    padding-top: 50%;
  }
}

.popuap__overlay {
  width: 100%;
  height: 100%;
}

.popup__inner {
  max-width: 310px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 27px 24px 20px 24px;
}

.popup__img {
  margin-bottom: 25px;
}

.popup__text {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
}

.popup__btn {
  background: #ffc01d;
  border: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  padding: 15px 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
