.breadcrumbs__list,.alter__breadcrumbs__list {
  display: flex;
}

.alter__breadcrumbs__list {
  align-items: center;
  margin-bottom: 8px;
}
.alter__breadcrumbs__item {

  font-family: 'Nunito Sans',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #858585;
  @include _425 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
.alter__breadcrumbs__divider {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  margin: 0 8px;
  background: #858585;
}


.breadcrumbs__item {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  margin-right: 6px;
  position: relative;

  &:first-letter {
    text-transform: uppercase;
  }

  &:not(:last-of-type) {
    padding-right: 6px;
    cursor: pointer;

    &:after {
      content: ">";
      position: absolute;
      right: -3px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @include _992 {
    font-size: 14px;
    line-height: 16px;
  }
}
