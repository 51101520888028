.about-wrap {
  margin-top: -10px;
  width: 75%;
  @include _992 {
    width: inherit;
  }

  h2 {
    font-family: $font2;
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0;
    font-weight: 700;
  }

  ul {
    ul {
      list-style: circle;
      padding-left: 15px;
      margin-left: 15px;
    }
  }

  li {
    font-family: $font2;
    font-size: 11px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  p {
    font-family: $font2;
    font-size: 11px;
    line-height: 20px;
    margin-bottom: 5px;

    &.mb {
      margin-bottom: 20px;
    }

    &.mt {
      margin-top: 40px;
    }
  }
}
.about-breadcrumbs {
  margin-top: -13px;
  margin-left: -3px;
}
.about-title {
  font-family: "play";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: $color--main;
  margin-top: 0;
}
.about-info-text {
  font-family: "roboto";
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  max-width: 620px;
  margin-top: 15px;
  margin-bottom: 20px;
  color: #000000;
}

.about-catalog-link {
  font-family: "roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 21.7778px;
  line-height: 26px;
  text-align: center;
  margin-left: -63px;
  text-decoration-line: underline;
  color: $color--main;
  display: block;
  @include _992 {
    margin-left: 0;
  }
}
