.extra-page__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.extra-page__header {
  min-height: 390px;
  margin-bottom: 50px;
}

.extra-page__breadcrumbs {
  margin-top: -25px;
  margin-left: -3px;
  margin-bottom: 5px;
}

.extra-page__title {
  font-family: $font2;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: $color--main;
  margin-bottom: 20px;
}
