.cards__item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 17px 15px;
  width: 100%;

  @include _992 {
    padding: 12px 15px;
  }

  @include _768 {
    padding-top: 20px;
  }
}

.item__inner {
  @include _992 {
    overflow: hidden;
  }
}

.catalog__regionFlag {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 36px;
  height: 24px;
}

.cards__info {
  z-index: 10;
  position: relative;
  display: inline-block;

  &--end {
    align-self: flex-end;
    margin-right: 7px;
  }
}

.cards__title {
  font-family: $font2;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #ffffff;

  @include _768 {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 5px;
  }

  @include _375 {
    font-size: 16px;
  }
}

.cards__text {
  font-family: $font2;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  text-transform: lowercase;
  color: #ffffff;

  @include _768 {
    font-size: 10px;
    line-height: 12px;
  }
}

.img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.cards__img--wrap {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

.cards__wrap {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-height: 350px;
  justify-content: center;

  @include _992 {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.cards__column {
  &--01 {
    max-height: 100%;
    width: 21%;
    margin-left: -3px;

    .items {
      display: none;
    }

    .cards__item:last-of-type {
      display: none;
    }

    @include _992 {
      width: 32%;
      height: 125px;
    }

    @include _768 {
      display: none;
    }
  }

  &--02 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 23.5%;
    max-height: 100%;
    margin-left: 2%;

    & .cards__item {
      height: 58%;

      &:first-child {
        height: 35%;
      }
    }

    @include _992 {
      width: 32%;

      & .cards__item:first-child {
        display: none;

        @include _768 {
          display: flex;
          height: 95px;
          margin-bottom: 14px;
        }
      }

      & .cards__item {
        width: 100%;
        height: 100%;

        @include _768 {
          height: 95px;
        }
      }
    }

    @include _768 {
      width: 48%;
      margin-left: 0;
    }
  }

  &--03 {
    width: 50.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 2.6%;

    & .cards__item--wide {
      height: 35%;
      margin-bottom: 26px;

      @include _992 {
        display: none;
      }
    }

    & .cards__item--narrow {
      height: 57.5%;
      width: 47.5%;

      @include _992 {
        width: 100%;
        height: 100%;
      }

      @include _768 {
        height: 95px;
      }
    }

    @include _992 {
      width: 32%;
      margin-left: 11px;

      & .cards__item--narrow:last-child {
        display: none;

        @include _768 {
          display: flex;
          margin-top: 14px;
        }
      }
    }

    @include _768 {
      width: 48%;
    }
  }
}

.main__cards-type .items {
  display: flex;

  @include _992 {
    display: none;
  }
}

.main__cards-type .cards__column--01 .cards__item--narrow:first-of-type {
  display: none;

  @include _992 {
    display: flex;
    height: 125px;
    margin-bottom: 15px;
  }
}

.main__cards-type .cards__column--01 .cards__item:last-of-type {
  @include _992 {
    display: flex;
    height: 125px;
  }
}

.main__cards-type .cards__column--02 {
  @include _992 {
    justify-content: unset;
    margin-left: 15px;
  }
}

.main__cards-type .cards__column--02 .cards__item:first-child {
  @include _992 {
    display: flex;
    height: 125px;
    margin-bottom: 15px;
  }
}

.main__cards-type .cards__column--03 {
  @include _992 {
    margin-left: 15px;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.main__cards-type .cards__column--03 .cards__item--narrow:last-child {
  @include _992 {
    display: flex;
    height: 125px;
  }
}

.main__cards-type .cards__column--01 {
  @include _992 {
    height: 100%;
  }
}

.main__cards-type .cards__column--03 .cards__item--narrow {
  @include _992 {
    height: 125px;
    margin-bottom: 15px;
  }
}

.main__cards-type .cards__column--02 .cards__item {
  @include _992 {
    height: 125px;
  }
}

.cards__item {
  &--short {
    min-height: 128px;
  }

  &--medium {
    min-height: 200px;
  }

  &--tall {
    min-height: 344px;
  }

  @include _992 {
    min-height: 128px;
  }
}

.cards__grid {
  margin: 0 auto;

  &--mobile {
    display: none;

    @include _992 {
      display: block;
    }
  }

  @include _992 {
    margin-bottom: 48px;
  }

  @include _768 {
    margin-bottom: 32px;
  }
}

.cards__row {
  display: flex;
  margin: 0 -7.5px;
  flex-wrap: wrap;

  &:not(:last-of-type) {
    margin-bottom: 15px;

    @include _768 {
      margin-bottom: 6px;
    }
  }
}

.cards__col {
  width: 100%;
  padding: 0 7.5px;
  box-sizing: border-box;
  flex-shrink: 1;
  flex-grow: 1;

  &--25 {
    flex-basis: 25%;
  }

  &--50 {
    flex-basis: 50%;
  }

  @include _992 {
    flex-basis: 33.33%;
  }

  @include _768 {
    flex-basis: 100%;

    &:not(:last-of-type) {
      margin-bottom: 9px;
    }
  }
}
