.info-main-wrapper {
  @include _992 {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  @include _768 {
    padding-top: 72px;
  }
}

.info-wrapper {
  display: flex;
  margin-bottom: 60px;
  padding-top: 13px;

  @include _768 {
    margin-bottom: 30px;
  }
}

.main__row--info {
  min-height: auto;
}

.info-aside {
  padding-right: 43px;
  max-width: 290px;
  width: 100%;

  @include _992 {
    margin-left: 0;
    display: none;
  }
}

.info-cards {
  display: none;
  @include _992 {
    display: block;
    margin-bottom: 24px;
  }
}

.info-cards-bottom {
  @include _992 {
    display: none;
  }
}

.info-providers {
  @include _992 {
    display: none;
  }
}

.info-footer {
  display: none;
  @include _992 {
    display: block;
    max-width: 100vw;
  }
  @include _768 {
    max-width: 100vw;
  }
}
