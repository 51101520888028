.winery-info__footer {

  @include _992 {
    display: block;
    height: auto;
    margin: 30px 0 0;
  }

  .layout__col--sidebar {
    padding-left: 0;

    @include _992 {
      max-width: none;
      padding: 0;

      .swiper-slide {
        width: auto;
      }
    }
  }

  .layout__col--similarProducts {
    max-width: calc(100% - 280px);
    padding: 0;

    @include _992 {
      max-width: none;
    }

    .catalog__item-inner {
      @include _992 {
        border: none;
      }
    }

    .catalog__list {
      @include _992 {
        margin: 0;
      }
    }
  }
}
