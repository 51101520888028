.profile__popup {
  max-width: 500px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  cursor: auto;
}

.profile__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 0;
  cursor: pointer;
}

.profile {
  padding-bottom: 30px;

  @include _992 {
    padding-top: 70px;
  }

  @include _768 {
    padding-top: 25px;
  }
}

.profile__breadcrumbs-wp {
  @include _992 {
    margin-bottom: 15px;
  }

  @include _768 {
    .breadcrumbs__item {
      font-size: 11px;
      line-height: 15px;
    }
  }
}

.profile__title {
  font-weight: bold;
  color: #026db0;
  font-family: $font2;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 20px;

  @include _992 {
    font-size: 24px;
    line-height: 35px;
  }

  @include _768 {
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 16px;
  }
}

.profile__btn {
  font-weight: 900;
  font-size: 21.7778px;
  line-height: 26px;
  text-align: center;
  padding: 8px 0;
  width: 100%;
  max-width: 275px;
  align-self: center;
  min-height: 56px;

  @include _768 {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.profile__login-btn {
  margin: 0 auto;
}

.profile__row {
  display: flex;
  margin: 0 -15px;

  @include _992 {
    flex-direction: column;
  }
}

.profile__col {
  padding: 0 15px;
  box-sizing: border-box;

  &--left {
    flex-grow: 1;
    min-width: 300px;
    flex-shrink: 0;

    @include _992 {
      width: 100%;
      min-width: 1px;
      margin-bottom: 5px;
    }
  }

  &--right {
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-shrink: 1;

    @include _992 {
      width: 100%;
      max-width: none;
    }
  }
}

.profile__main {
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  padding: 25px 45px 25px 35px;
  display: flex;
  align-items: stretch;
  min-height: 300px;

  @include _992 {
    box-shadow: none;
    padding: 20px 0;
    min-height: 1px;
  }
}

.profile__nav-list {
  display: flex;
  flex-direction: column;

  @include _992 {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 17px 10px;
  }
}

.profile__nav-item {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  font-family: $font2;

  @include _768 {
    font-size: 18px;
  }

  &:not(:last-of-type) {
    margin-bottom: 23px;

    @include _768 {
      margin-bottom: 31px;
    }
  }

  &:hover,
  &--active {
    color: $color--main;
    font-weight: 700;
  }

  a {
    display: block;
    font-size: inherit;
    line-height: inherit;
  }
}

.profile__form {
  width: 100%;
}

.profile__form-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  margin-bottom: 25px;

  @include _992 {
    margin: 0 -15px;
    margin-bottom: 25px;
  }
}

.profile__form-field {
  padding: 0 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;

  @include _992 {
    padding: 0 15px;
  }

  @include _768 {
    width: 100%;
  }
}

.profile__from-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  font-family: $font2;
  position: relative;
  margin-bottom: 2px;

  &--require::after {
    top: 0;
    right: -10px;
    content: "*";
    position: absolute;
    color: red;
  }
}

.profile__form-input {
  border: 1px solid #b0b0b0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  padding: 7px 10px;

  .profile__form-field--error & {
    border: 1px solid red;
  }
}

.profile__form-field-error-text {
  width: 100%;
  color: red;
  text-align: center;
  padding-top: 5px;
}

.profile__btn {
  margin: 0 auto;
}

.profile__tab-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
