.popup__position--modal {
  width: 612px;
  margin: 0 auto;

  .position__offers-header {
    padding-top: 0;
  }

  .position__offers--title {
    padding-left: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }

  .position__offers-list {
    padding-left: 16px;
  }
}

.popup__position--content {
  padding: 19px 28px 19px 9px;
  background-color: white;
  font-family: $font2;
}

.popup__position--modal-wrap {
  position: relative;
}

.popup__position__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;

  &:first-letter {
    text-transform: uppercase;
  }
}

.popup__position__volume {
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  font-family: $font2;
  color: #575757;
  margin-bottom: 11px;
}

.popup__position-info-wrapper {
  display: flex;
  padding-left: 3px;
  margin-bottom: 13px;
}

.popup__vendor-code {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #858585;
  min-width: 125px;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
}

.popup__brand-link {
  min-width: 145px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #858585;

  span {
    color: $color--main;
  }
}

.popup__rating {
  width: 120px;
}

.popup__views-count {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #858585;
}

.popup__btn {
  width: 100%;
  max-width: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  min-height: 28px;
  color: #000000;
  padding: 0;
  display: flex;
  align-items: center;
}

.mySwiper {
  height: 200px;
}

.popup__position-image-column {
  display: flex;

  img {
    height: 171px;
    max-width: 100px;
    object-fit: contain;
    margin-left: 12px;
  }
}

.popup__position--offers-column {
  flex-grow: 1;
}

.popup__position--offers-column .position__offers--inner {
  box-shadow: none;
  overflow: inherit;
  min-width: 100px;
  margin-left: 34px;
}

.popup__position--image-wrap {
  display: flex;
  flex-direction: column;
  width: 120px;
  margin-right: 18px;
  margin-left: 10px;
}

.popup__position--image {
  width: 120px;
  height: 160px;
  margin-bottom: 13px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.popup__position--offers-column .position__offers--wrapper {
  margin-left: 0;
  margin-right: 0;
}

.popup__position--offers-column .position__offers--title {
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 6px;
  padding-top: 10px;
  margin-left: 0;

  span {
    margin-left: 0;
  }
}

.popup__position--offers-column .position__offers--info {
  flex-direction: row;
  flex-wrap: wrap;
  width: 213px;
  margin-top: 10px;
  margin-right: 14px;
}

.popup__position--offers-column .position__offers--supplier {
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  font-weight: normal;
  margin-right: 7px;
}

.popup__position--offers-column .position__offers-reviews {
  font-size: 9px;
  line-height: 10px;
  margin-top: 2px;
  margin-bottom: 0;
}

.popup__position--offers-column .position__offers-goods {
  font-size: 12px;
  line-height: 14px;
}

.popup__position--offers-column .position__price-value {
  margin-right: -2px;
  margin-top: 6px;
}

.popup__position--offers-column .position__price-second-btn {
  margin-right: 2px;
}

.popup__position--offers-column .position__price--wrap {
  margin-left: 0;
}

.popup__position--offers-column .position__price--item {
  margin-bottom: 20px;

  p:first-of-type {
    font-size: 12px;
    line-height: 14px;
  }

  p:last-of-type {
    font-size: 7px;
    line-height: 8px;
    margin-top: 5px;
  }

  span {
    font-size: 12px;
    line-height: 14px;
  }

  span:not(:last-of-type) {
    font-size: 18px;
    line-height: 21px;
  }
}

.popup__position--button {
  font-size: 10px;
  line-height: 12px;
  width: 109px;
  margin-top: 6px;
  margin-left: 10px;
}

.position__popup--btn-close-wrap {
  position: absolute;
  bottom: 91%;
  left: 94.5%;
  cursor: pointer;
  z-index: 11150;

  svg {
    z-index: 11150;
  }
}

.rc-scrollbars-view {
  background: white;
}

.rc-scrollbars-track {
  bottom: 23px !important;
}

.rc-scrollbars-container {
  position: relative;
}

.thumb-vertical {
  background-color: #026db0 !important;
  height: 56px !important;
  border-radius: 0 !important;
}

.popup__position--review-count {
  font-family: "play";
  font-style: normal;
  font-weight: normal;
  font-size: 9.96226px;
  margin-left: 12px;
  line-height: 12px;
  text-decoration-line: underline;
  color: #858585;
}

.popup__position--review-stars {
  margin-left: 27.42px;
  margin-top: -4px;
}
.popup__position--review-star {
  margin-right: 3px;
}
.popup__position--views {
  text-decoration: none !important;
  font-family: "play";
  font-style: normal;
  font-weight: normal;
  font-size: 9.96226px;
  line-height: 12px;
  color: #858585;
  margin-left: 21px;
}

.popup__position--info-btn {
  visibility: hidden;
  position: absolute;
  margin-top: 72px;
  margin-left: 6px;
}
.popup__position--image-wrap {
  &:hover .popup__position--info-btn {
    visibility: visible;
  }
}

.popup__position-wrapper {
  width: 276px;
  height: 114px;
  background: $color--main;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  position: absolute;
  margin-top: 40px;
  margin-left: -4px;
  z-index: 100;
}

.popup__position-rating {
  font-family: "roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #ffc01d;
  margin-top: 8px;
  margin-left: 13px;
}

.popup__position--all-ratings {
  font-family: "roboto";
  font-style: normal;
  font-size: 12px;
  color: #ffffff;
  line-height: 14.06px;
  max-width: 82px;
  margin-left: 13px;
  margin-top: 10px;
}

.popup__position--all-ratings-quantity {
  text-decoration: underline;
  cursor: pointer;
}

.popup__position--info-wrapper {
  margin-left: 15px;
  margin-top: 13px;
}

.popup__position--info-item-wrapper {
  display: flex;
  margin-bottom: 6px;
}

.popup__position--info-item-label {
  width: 38px;
  height: 16px;
  font-family: "play";
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 9px;
  color: #ffffff;
}
.popup__position--info-item-points {
  display: flex;
  margin-left: 7px;
  margin-top: 5px;
}
.popup__position--info-item-point {
  width: 18.96px;
  height: 3px;
  margin-right: 4.5px;
}

.position__popup--reviews-btn-close-wrap {
  position: absolute;
  bottom: 77%;
  left: 89.5%;
  cursor: pointer;
  z-index: 11150;

  svg {
    z-index: 11150;
  }
}
