/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/roboto-v27-latin_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v27-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v27-latin_cyrillic-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v27-latin_cyrillic-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v27-latin_cyrillic-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v27-latin_cyrillic-regular.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v27-latin_cyrillic-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v27-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v27-latin_cyrillic-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v27-latin_cyrillic-500.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v27-latin_cyrillic-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v27-latin_cyrillic-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/roboto-v27-latin_cyrillic-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v27-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v27-latin_cyrillic-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v27-latin_cyrillic-700.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v27-latin_cyrillic-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v27-latin_cyrillic-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

/* nunito-sans-800 - latin_cyrillic */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('../fonts/nunito-sans-v11-latin_cyrillic-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v11-latin_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v11-latin_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v11-latin_cyrillic-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v11-latin_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v11-latin_cyrillic-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* roboto-900 - latin_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/roboto-v27-latin_cyrillic-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v27-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v27-latin_cyrillic-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v27-latin_cyrillic-900.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v27-latin_cyrillic-900.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v27-latin_cyrillic-900.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

/* play-regular - cyrillic */
@font-face {
  font-family: "Play";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/play-v12-cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/play-v12-cyrillic-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/play-v12-cyrillic-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/play-v12-cyrillic-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/play-v12-cyrillic-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/play-v12-cyrillic-regular.svg#Play") format("svg"); /* Legacy iOS */
}
/* play-700 - cyrillic */
@font-face {
  font-family: "Play";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/play-v12-cyrillic-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/play-v12-cyrillic-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/play-v12-cyrillic-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/play-v12-cyrillic-700.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/play-v12-cyrillic-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/play-v12-cyrillic-700.svg#Play") format("svg"); /* Legacy iOS */
}

/* nunito-sans-regular - latin_cyrillic */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/nunito-sans-v11-latin_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/nunito-sans-v11-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/nunito-sans-v11-latin_cyrillic-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/nunito-sans-v11-latin_cyrillic-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/nunito-sans-v11-latin_cyrillic-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/nunito-sans-v11-latin_cyrillic-regular.svg#NunitoSans")
      format("svg"); /* Legacy iOS */
}
/* nunito-sans-600 - latin_cyrillic */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/nunito-sans-v11-latin_cyrillic-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/nunito-sans-v11-latin_cyrillic-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/nunito-sans-v11-latin_cyrillic-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/nunito-sans-v11-latin_cyrillic-600.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/nunito-sans-v11-latin_cyrillic-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/nunito-sans-v11-latin_cyrillic-600.svg#NunitoSans")
      format("svg"); /* Legacy iOS */
}

/* nunito-sans-700 - latin_cyrillic */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/nunito-sans-v11-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v11-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v11-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v11-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v11-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v11-latin_cyrillic-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-900 - latin_cyrillic */
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/nunito-sans-v11-latin_cyrillic-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/nunito-sans-v11-latin_cyrillic-900.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/nunito-sans-v11-latin_cyrillic-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/nunito-sans-v11-latin_cyrillic-900.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/nunito-sans-v11-latin_cyrillic-900.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/nunito-sans-v11-latin_cyrillic-900.svg#NunitoSans")
      format("svg"); /* Legacy iOS */
}
